import React, { Fragment, useState, useEffect } from 'react';
import BreadCrumb from '../../../components/breadcrumb';
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  TablePagination,
  ButtonGroup,
  TableSortLabel,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  Tooltip,
  Chip,
  Modal,
  CircularProgress,
} from '@mui/material';
import Colors from '../../../style';
import Filter from '../../../components/filter';
import { PrimaryButton, PrimaryIconButton, SecondaryButton } from '../../../components/buttons';
import { MoreHoriz, KeyboardArrowLeft, KeyboardArrowRight, Search } from '@mui/icons-material';
import { BusinessIcon2, MemberListIcon } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import AdminServices from '../../../api/admin/AdminServices';
import moment from 'moment';
import { InputField, DateField, AutoCompleteField } from '../../../components/inputfields';
import { PageDot } from '../../../components/UI';
import { SuccessToaster } from '../../../components/toaster';
import { ConfirmationDialog } from '../../../components/dialog';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import { SaveAlt } from '@mui/icons-material';
import useAuth from '../../../hooks/useAuth';

const ITEM_HEIGHT = 48;

const tableHead = [
  {
    name: "ID",
    sortOrder: { column: "offer_id", direction: 'asc' },
    visible: true
  },
  {
    name: "Offers",
    sortOrder: { column: "offer_name", direction: 'asc' },
    visible: true
  },
  {
    name: "Business",
    sortOrder: { column: "business", direction: 'asc' },
    visible: true
  },
  {
    name: "Brand",
    sortOrder: { column: "brand", direction: 'asc' },
    visible: true
  },
  {
    name: "Branch",
    sortOrder: { column: "establishment", direction: 'asc' },
    visible: true
  },
  {
    name: "Category",
    sortOrder: { column: "offer_type", direction: 'asc' },
    visible: true
  },
  {
    name: "Start Date",
    sortOrder: { column: "start_date", direction: 'asc' },
    visible: true
  },
  {
    name: "End Date",
    sortOrder: { column: "end_date", direction: 'asc' },
    visible: true
  },
  {
    name: "Country",
    sortOrder: { column: "country", direction: 'asc' },
    visible: true
  },
  {
    name: "City",
    sortOrder: { column: "city", direction: 'asc' },
    visible: true
  },
  {
    name: "Status",
    sortOrder: { column: "is_active", direction: 'asc' },
    visible: true
  },
  {
    name: "Action",
    sortOrder: { column: "action", direction: 'asc' },
    visible: true
  },
];

const columnsWithSelect = [
  { name: "Select", sortOrder: { column: "select", direction: 'asc' }, visible: true },
  ...tableHead,
];

const ProgressModal = ({ open, progress, total }) => {
  return (
    <Modal open={open} aria-labelledby="progress-modal-title" aria-describedby="progress-modal-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: Colors.dark1,
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        <CircularProgress sx={{ color: Colors.secondary }} />
        <Typography variant="h6" sx={{ mt: 2, color: Colors.white }}>
          Processing {progress} of {total} requests...
        </Typography>
      </Box>
    </Modal>
  );
};

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBack = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNext = (event) => {
    onPageChange(event, page + 1);
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <ButtonGroup
        sx={{
          borderRadius: 2,
          background: Colors.dark1,
        }}
      >
        <IconButton
          onClick={handleBack}
          disabled={page == 0}
          aria-label="previous page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNext}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </ButtonGroup>
    </Box>
  )
}

function OffersRequest() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(tableHead);
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [secLoading, setSecLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [offerName, setOfferName] = useState("");
  const [progressLoading, setProgressLoading] = useState(false);
  const [progress, setProgress] = useState({ current: 0, total: 0 });
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set()); // To track selected rows
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(new Set());
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [search, setSearch] = useState(null)
  const navigate = useNavigate();
  const { role } = useAuth();

  const handleToggleColumn = (column) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.name === column ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const handleSort = (column) => {
    setColumns((prevColumns) => {
      const newColumns = prevColumns.map((col) =>
        col.sortOrder.column === column
          ? { ...col, sortOrder: { column, direction: col.sortOrder.direction === 'asc' ? 'desc' : 'asc' } }
          : col
      );

      const sortedRows = [...rows].sort((a, b) => {
        const col = newColumns.find(col => col.sortOrder.column === column);
        const direction = col.sortOrder.direction;
        if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
        if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
        return 0;
      });

      setRows(sortedRows);
      return newColumns;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getOffersData(newPage + 1, limit);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    getOffersData(page, event.target.value);
  };

  const handleSettingsClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchor(null);
  };

  const getOffersData = async (
    page, limit, is_approved, offer_id, created_at_from, created_at_to, offer_name, city_id, search
  ) => {
    try {
      const result = await AdminServices.getAllOffers(
        page ? page : 1,
        limit ? limit : 20,
        is_approved ? is_approved : "",
        offer_id ? offer_id : "",
        created_at_from ? created_at_from : "",
        created_at_to ? created_at_to : "",
        offer_name ? offer_name : "",
        city_id ? city_id : "",
        search ? search : ""
      );
      if (result.responseCode == 200) {
        setCount(result?.data?.totalOffers)
        const shallowCopy = result?.data?.Offers?.map((item) => {
          const obj = { ...item };
          tableHead.forEach((value) => {
            if (value.name == "Action") {
              obj[value.sortOrder.column] = "action";
            } else if (value.name == "ID") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Offers") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Business") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Brand") {
              obj[value.sortOrder.column] = item[value.sortOrder.column]?.name;
            } else if (value.name == "Branch") {
              obj[value.sortOrder.column] = item[value.sortOrder.column]?.name;
            } else if (value.name == "Category") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Start Date") {
              obj[value.sortOrder.column] = moment(item[value.sortOrder.column]).format("DD MMM YYYY");
            } else if (value.name == "End Date") {
              obj[value.sortOrder.column] = moment(item[value.sortOrder.column]).format("DD MMM YYYY");
            } else if (value.name == "Country") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "City") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            }
          });
          obj.visible = true;
          return obj;
        })
        setRows(shallowCopy);
        const newSelectedRowsPerPage = new Set(selectedRowsPerPage);
        shallowCopy.forEach(row => {
          if (selectedRows.has(row._id)) {
            newSelectedRowsPerPage.add(row._id);
          }
        });
        setSelectedRowsPerPage(newSelectedRowsPerPage);
        const allSelected = shallowCopy.every(row => selectedRows.has(row._id));
        setIsSelectAll(allSelected);
      }
    } catch (e) {
      console.log(e)
    }
  };

  const handleStatusApproved = async () => {
    setLoading(true);
    try {
      const obj = {
        offer_id: data?._id,
        is_approved: true,
business_user_id: data?.business_user_id,
offer_name: data?.offer_name
      };
      const result = await AdminServices.approveOffer(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      getOffersData(page, limit);
      setOpen(false);
      setLoading(false);
    }
  };

  const handleStatusReject = async () => {
    setSecLoading(true);
    try {
      const obj = {
        offer_id: data?._id,
        is_approved: false,
business_user_id: data?.business_user_id,
offer_name: data?.offer_name
      };
      const result = await AdminServices.approveOffer(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      getOffersData(page, limit);
      setOpen(false);
      setSecLoading(false);
    }
  };

  const handleNavigate = (row) => {
    navigate(`/offer-detail/${row._id}`, {
      state: {
        navigateFrom: "request",
      }
    });
  };

  const handleReset = () => {
    setSearch(null);
    setSelectedId("");
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setOfferName("");
    setSelectedCountry(null);
    setSelectedCity(null);
    getOffersData(page, limit);
  };

  const handleDownload = () => {
    const head = tableHead.filter((item) => item.name != "Action").map((item) => item.name);
    const data = rows.map(item => [
      item.offer_id,
      item.offer_name,
      item.business,
      item.brand,
      item.establishment,
      item.offer_type.map(item => item.name).join(", "),
      item.start_date,
      item.is_approved == true ? "Approved" : "Pending"
    ]);

    const ws = XLSX.utils.aoa_to_sheet([head, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Convert the workbook to an array buffer
    const buf = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the file using FileSaver.js
    saveAs(new Blob([buf]), "offer.xlsx");
  };

  const handleSelectAll = (isChecked) => {
    setIsSelectAll(isChecked);

    const newSelectedRows = new Set(selectedRows);

    if (isChecked) {
      rows.forEach(row => {
        if (row.is_approved == null) {
          newSelectedRows.add(row._id)
        }
      });
      setSelectedRowsPerPage(new Set(rows.filter(row => row.is_approved == null).map(row => row._id)));
    } else {
      rows.forEach(row => newSelectedRows.delete(row._id));
      setSelectedRowsPerPage(new Set());
    }

    setSelectedRows(newSelectedRows)
  };

  const handleCheckboxChange = (event, rowId) => {
    const isChecked = event.target.checked;
    const newSelectedRows = new Set(selectedRows);

    if (isChecked) {
      newSelectedRows.add(rowId);
    } else {
      newSelectedRows.delete(rowId);
    }

    setSelectedRows(newSelectedRows);

    // Update selectedRowsPerPage
    const newSelectedRowsPerPage = new Set(selectedRowsPerPage);
    if (isChecked) {
      newSelectedRowsPerPage.add(rowId);
    } else {
      newSelectedRowsPerPage.delete(rowId);
    }
    setSelectedRowsPerPage(newSelectedRowsPerPage);

    // Update isSelectAll based on the current page's rows
    setIsSelectAll(rows.every(row => newSelectedRowsPerPage.has(row._id)));
  };

  const handleApproveAll = async (ids) => {
    setProgressLoading(true);
    setProgress({ current: 1, total: ids.length });

    try {
      for (const id of ids) {
        const obj = { offer_id: id, is_approved: true };
        try {
          await AdminServices.approveOffer(obj);
        } catch (err) {
          console.error('Error processing id:', id, err);
        } finally {
          setProgress((prevProgress) => ({ ...prevProgress, current: prevProgress.current + 1 }));
        }
      }
    } catch (error) {
      console.error('Error during approval process:', error);
    } finally {
      setProgressLoading(false);
      selectedRows.clear();
      setIsSelectAll(false);
      SuccessToaster("All offers accepted successfully");
      getOffersData();
    }
  };

  const handleRejectAll = async (ids) => {
    setProgressLoading(true);
    setProgress({ current: 1, total: ids.length });

    try {
      for (const id of ids) {
        const obj = { offer_id: id, is_approved: false };
        try {
          await AdminServices.approveOffer(obj);
        } catch (err) {
          console.error('Error processing id:', id, err);
        } finally {
          setProgress((prevProgress) => ({ ...prevProgress, current: prevProgress.current + 1 }));
        }
      }
    } catch (error) {
      console.error('Error during rejection process:', error);
    } finally {
      setProgressLoading(false);
      selectedRows.clear();
      setIsSelectAll(false);
      SuccessToaster("All offers rejected successfully");
      getOffersData();
    }
  };

  const getCountries = async () => {
    try {
      const result = await AdminServices.getCountries();
      if (result.responseCode == 200) {
        setCountries(result.data.countries);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCities = async (id) => {
    try {
      const result = await AdminServices.getCities(id);
      if (result.responseCode == 200) {
        setCities(result.data.cities);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOffersData();
    getCountries();
  }, []);

  return (
    <Fragment>
      <ProgressModal
        open={progressLoading}
        progress={progress.current}
        total={progress.total}
      />
      <ConfirmationDialog
        loading={loading}
        open={open}
        onClose={() => setOpen(false)}
        onClick={handleStatusApproved}
        title={"Are you sure"}
        prmBtnTitle={"Approve"}
        secBtnTitle={"Reject"}
        secOnClick={handleStatusReject}
        secLoading={secLoading}
      />
      <Grid container alignItems={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb pageName={"Offers Request"} />
        </Grid>
        <Grid item md={11.3}>
          <Box
            sx={{
              p: 2
            }}
          >
            <Filter
              onClick={handleReset}
              items={
                [
                  <Grid item md={role == "admin" ? 2.4 : 4}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        width: "100%"
                      }}
                    >
                      <InputField
                        value={search}
                        fullWidth={true}
                        placeholder={"Search"}
                        type={"text"}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value != "") {
                            setSearch(value);
                          } else {
                            setSearch(null);
                          }
                        }}
                      />
                    </Box>
                  </Grid>,

                  <Grid item md={role == "admin" ? 2.15 : 3.4}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <DateField
                      value={selectedFromDate}
                      placeholder={"From"}
                      onChange={(date) => setSelectedFromDate(date)}
                    />
                  </Grid>,

                  <Grid item md={role == "admin" ? 2.15 : 3.4}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <DateField
                      value={selectedToDate}
                      placeholder={"To"}
                      onChange={(date) => setSelectedToDate(date)}
                    />
                  </Grid>,

                  role == "admin" && (
                    <Fragment>
                      <Grid item md={2.15}
                        sx={{
                          borderRight: `1px solid ${Colors.dark2}`
                        }}
                      >
                        <AutoCompleteField
                          value={selectedCountry}
                          fullWidth={true}
                          select={true}
                          options={countries}
                          placeholder={"Country"}
                          onChange={(e, newValue) => {
                            setSelectedCountry(newValue);
                            getCities(newValue.country_id);
                          }}
                        />
                      </Grid>

                      <Grid item md={2.15}
                        sx={{
                          borderRight: `1px solid ${Colors.dark2}`
                        }}
                      >
                        <AutoCompleteField
                          disabled={selectedCountry == null ? true : false}
                          value={selectedCity}
                          fullWidth={true}
                          select={true}
                          options={cities}
                          placeholder={"City"}
                          onChange={(e, newValue) => {
                            setSelectedCity(newValue);
                          }}
                        />
                      </Grid>
                    </Fragment>
                  ),
                ]
              }
            />
          </Box>
        </Grid>
        <Grid item md={0.7}>
          <Box
            sx={{ py: 2, pr: 2 }}
          >
            <PrimaryIconButton
              onClick={() => getOffersData(
                "",
                "",
                "",
                selectedId,
                selectedFromDate,
                selectedToDate,
                offerName,
                selectedCity?._id,
                search
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          background: Colors.dark3,
          p: "0px 16px"
        }}
      >
        <Box
          sx={{
            background: Colors.dark1,
            p: "16px 24px 8px 24px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            borderRadius: "20px",
            height: "calc(100vh - 293px)"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative"
            }}
          >
            <PageDot top={"14px"} left={"-24px"} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2
              }}
            >
              <BusinessIcon2 />
              <Typography sx={{ color: Colors.white }}>
                Offers Requests
              </Typography>
              <PrimaryButton
                onClick={() => setIsSelectMode(!isSelectMode)}
                title={isSelectMode ? "Cancel" : "Select"}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center"
              }}
            >
              {isSelectMode && selectedRows.size > 0 && (
                <PrimaryButton
                  title={"Approve All"}
                  onClick={() => handleApproveAll(Array.from(selectedRows))}
                />
              )}
              {isSelectMode && selectedRows.size > 0 && (
                <SecondaryButton
                  title={"Reject All"}
                  onClick={() => handleRejectAll(Array.from(selectedRows))}
                />
              )}
              <IconButton
                sx={{
                  background: Colors.buttonGradient
                }}
                onClick={() => handleDownload()}
              >
                <SaveAlt sx={{ color: Colors.white }} />
              </IconButton>
              <IconButton
                onClick={handleSettingsClick}
              >
                <MoreHoriz sx={{ color: Colors.white }} />
              </IconButton>
            </Box>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={handleSettingsClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    background: Colors.dark3,
                    color: Colors.white,
                    overflow: 'visible',
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 15,
                      width: 10,
                      height: 10,
                      bgcolor: Colors.dark3,
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 1,
                    },
                    ".MuiList-root": {
                      p: 0
                    }
                  }
                }
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box
                component={"div"}
                sx={{ maxHeight: ITEM_HEIGHT * 4.5, overflowY: 'auto' }}
              >
                {columns.map((col) => (
                  <MenuItem key={col.name}>
                    <Checkbox
                      sx={{
                        color: `${Colors.primary} !important`
                      }}
                      checked={col.visible}
                      onChange={() => handleToggleColumn(col.name)}
                    />
                    <ListItemText
                      primary={col.name}
                    />
                  </MenuItem>
                ))}
              </Box>
            </Menu>
          </Box>
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  background: Colors.dark3
                }}
              >
                <TableRow>
                  {(isSelectMode ? columnsWithSelect : columns).filter((cell) => cell.visible).map((cell, ind, visibleColumns) => (
                    <TableCell
                      key={ind}
                      sx={{
                        p: "10px 16px",
                        display: "table-cell",
                        borderBottom: "none",
                        borderRadius: ind == 0
                          ? "20px 0 0 20px"
                          : ind == visibleColumns.length - 1
                            ? "0 20px 20px 0"
                            : "none",
                      }}
                    >
                      {cell.name == "Select" ? (
                        <Checkbox
                          sx={{
                            p: 0,
                            color: `${Colors.primary} !important`
                          }}
                          checked={isSelectAll}
                          onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                      ) : cell.name != "Action" ? (
                        <TableSortLabel
                          active={cell.sortOrder.column}
                          direction={cell.sortOrder.direction}
                          onClick={() => handleSort(cell.sortOrder.column)}
                          sx={{
                            "svg": {
                              fontSize: "12px",
                              color: `${Colors.white} !important`,
                            },
                            width: (cell.name == "Start Date" || cell.name == "End Date") && "100px"
                          }}
                        >
                          <Box
                            sx={{
                              color: Colors.white,
                            }}
                          >
                            {cell.name}
                          </Box>
                        </TableSortLabel>
                      ) : (
                        <Box
                          sx={{
                            color: Colors.white,
                          }}
                        >
                          {cell.name}
                        </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, ind) => (
                  <TableRow key={ind}>
                    {(isSelectMode ? columnsWithSelect : columns).map((col, colInd) => col.visible && (
                      col.name == "Action" ? (
                        <TableCell key={colInd}
                          sx={{
                            p: "8px 16px"
                          }}
                        >
                          <IconButton
                            sx={{
                              color: Colors.white,
                              whiteSpace: "nowrap"
                            }}
                            onClick={() => handleNavigate(row)}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </TableCell>
                      ) : col.name == "Name" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px",
                            whiteSpace: "nowrap"
                          }}
                        >
                          <Tooltip
                            title={`${row[col.sortOrder.column]?.first_name} ${row[col.sortOrder.column]?.last_name}`}
                          >
                            {row[col.sortOrder.column]?.first_name + "..."}
                          </Tooltip>
                        </TableCell>
                      ) : col.name == "Category" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            whiteSpace: "nowrap",
                            p: "8px 16px"
                          }}
                        >
                          <Tooltip
                            title={row[col.sortOrder.column].map((item) => item?.name).join(", ")}
                          >
                            {row[col.sortOrder.column].length > 1
                              ? row[col.sortOrder.column][0]?.name + "..."
                              : row[col.sortOrder.column][0]?.name
                            }
                          </Tooltip>
                        </TableCell>
                      ) : col.name == "Status" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            whiteSpace: "nowrap",
                            p: "8px 16px"
                          }}
                        >
                          <Chip
                            component={"button"}
                            label={
                              row.is_approved
                                ? "Approved"
                                : row.is_approved === false
                                  ? "Rejected"
                                  : "Pending"
                            }
                            sx={{
                              background: row.is_approved
                                ? Colors.success + 19
                                : row.is_approved === false
                                  ? Colors.danger + 19
                                  : Colors.warning + 19,
                              color: row.is_approved
                                ? Colors.success
                                : row.is_approved === false
                                  ? Colors.danger
                                  : Colors.warning,
                              ":hover": {
                                background: row.is_approved
                                  ? Colors.success + 19
                                  : row.is_approved === false
                                    ? Colors.danger + 19
                                    : Colors.warning + 19,
                                color: row.is_approved
                                  ? Colors.success
                                  : row.is_approved === false
                                    ? Colors.danger
                                    : Colors.warning,
                              }
                            }}
                            onClick={() => {
                              if (row.is_approved == null) {
                                setOpen(true);
                                setData(row);
                              }
                            }}
                          />
                        </TableCell>
                      ) : col.name == "Select" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          <Checkbox
                            sx={{
                              p: 0,
                              color: `${Colors.primary} !important`,
                              "&.Mui-disabled": {
                                color: `${Colors.primary + 80} !important`,
                              }
                            }}
                            disabled={row.is_approved == null ? false : true}
                            checked={selectedRows.has(row._id)}
                            onChange={(e) => handleCheckboxChange(e, row._id)}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            whiteSpace: "nowrap",
                            p: "8px 16px"
                          }}
                        >
                          {row[col.sortOrder.column]}
                        </TableCell>
                      )
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Table>
        <TablePagination
          sx={{
            borderBottom: "none",
            borderRadius: "12px",
            color: Colors.white,
            ".MuiTablePagination-spacer": {
              flex: 1,
            },
            ".MuiToolbar-root": {
              gap: "12px",
              paddingRight: "24px",
              ".MuiInputBase-root": {
                mr: 1,
              }
            },
          }}
          slotProps={{
            select: {
              MenuProps: {
                slotProps: {
                  paper: {
                    sx: {
                      background: Colors.dark3
                    }
                  },
                },
                MenuListProps: {
                  sx: {
                    color: Colors.white,
                    "li": {
                      ":hover": {
                        background: Colors.buttonGradient
                      }
                    }
                  },
                },
              }
            }
          }}
          rowsPerPageOptions={[20, 50, 100]}
          colSpan={12}
          count={count}
          rowsPerPage={limit}
          page={page}
          labelRowsPerPage={"Showing"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Table>
    </Fragment>
  )
}

export default OffersRequest;