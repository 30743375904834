// import React, { Fragment, useEffect, useState } from 'react'
// import BreadCrumb from '../../components/breadcrumb';
// import Colors from '../../style';
// import { Avatar, Box, Button, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Menu, MenuItem, TextField, Typography } from '@mui/material';
// import { Add, KeyboardArrowDown } from '@mui/icons-material';
// import "@fontsource/inter"
// import styled from '@emotion/styled';

// function Chat() {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const ChatTextField = styled(TextField)(({ theme }) => ({
//     "& .MuiInputBase-input": {
//       backgroundColor: Colors.dark3,
//       borderRadius: "12px",
//       color: Colors.white,
//       "::placeholder": {
//         color: Colors.dark2
//       }
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       border: "none"
//     }
//   }))

//   return (
//     <Fragment>
//       <Grid container>
//         <Grid item md={12}>
//           <Box
//             sx={{
//               borderBottom: "1px solid #4b4b4b"
//             }}
//           >
//             <BreadCrumb
//               pageName={"Chat Support"}
//             />
//           </Box>
//         </Grid>
//         <Grid item md={12}>
//           <Grid
//             container
//             sx={{ p: 2 }}
//             columnSpacing={3}
//           >
//             <Grid item md={4}>
//               <Box
//                 sx={{
//                   background: Colors.dark1,
//                   borderRadius: "16px",
//                   display: "flex",
//                   flexDirection: "column",
//                   height: "100%"
//                 }}
//               >
//                 <Grid container>
//                   <Grid item md={12}
//                     sx={{
//                       borderBottom: `1px solid ${Colors.dark2}`
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "space-between",
//                         p: 2,
//                       }}
//                     >
//                       <Box
//                         sx={{
//                           display: "flex",
//                           alignItems: "center",
//                           gap: 1
//                         }}
//                       >
//                         <Button
//                           endIcon={<KeyboardArrowDown sx={{ color: Colors.white, fontSize: "24px !important" }} />}
//                           sx={{
//                             color: Colors.white,
//                             textTransform: "capitalize",
//                             fontSize: "16px",
//                             fontFamily: "Inter"
//                           }}
//                           onClick={handleClick}
//                         >
//                           Messages
//                         </Button>
//                         <Box
//                           sx={{
//                             width: "30px",
//                             height: "22px",
//                             borderRadius: "24px",
//                             background: Colors.white,
//                             display: "flex",
//                             alignItems: "center",
//                             justifyContent: "center"

//                           }}
//                         >
//                           <Typography
//                             sx={{
//                               color: Colors.dark1,
//                               fontSize: "12px",
//                               fontWeight: 600,
//                               fontFamily: "Inter",
//                               lineHeight: "18px"
//                             }}
//                           >
//                             12
//                           </Typography>
//                         </Box>
//                         <Menu
//                           id="basic-menu"
//                           anchorEl={anchorEl}
//                           open={open}
//                           onClose={handleClose}
//                           MenuListProps={{
//                             'aria-labelledby': 'basic-button',
//                           }}
//                           slotProps={{
//                             paper: {
//                               sx: {
//                                 background: Colors.dark3
//                               }
//                             }
//                           }}
//                         >
//                           <MenuItem
//                             onClick={handleClose}
//                             sx={{
//                               color: Colors.white,
//                               ":hover": {
//                                 background: Colors.buttonGradient
//                               }
//                             }}
//                           >
//                             All
//                           </MenuItem>
//                           <MenuItem
//                             onClick={handleClose}
//                             sx={{
//                               color: Colors.white,
//                               ":hover": {
//                                 background: Colors.buttonGradient
//                               }
//                             }}
//                           >
//                             Unread
//                           </MenuItem>
//                         </Menu>
//                       </Box>
//                       <IconButton
//                         sx={{
//                           background: Colors.buttonGradient,
//                           width: "32px",
//                           height: "32px"
//                         }}
//                       >
//                         <Add sx={{ color: Colors.dark1 }} />
//                       </IconButton>
//                     </Box>
//                   </Grid>
//                   <Grid item md={12}>
//                     <Box
//                       sx={{
//                         p: 2,
//                       }}
//                     >
//                       <ChatTextField
//                         fullWidth
//                         placeholder={"Search messages"}
//                       />
//                     </Box>
//                   </Grid>
//                 </Grid>
//                 <Box
//                   sx={{
//                     overflowY: "auto",
//                     maxHeight: "330px",
//                     p: "0px 16px 16px 16px",
//                     '&::-webkit-scrollbar': {
//                       display: 'none'
//                     }
//                   }}
//                 >
//                   <Grid container>
//                     <Grid item md={12}>
//                       <List sx={{ p: 0 }}>
//                         <ListItem sx={{ p: 0 }}>
//                           <ListItemButton sx={{ px: 0, py: 2, gap: 2, alignItems: "flex-start" }}>
//                             <Avatar
//                               variant={"rounded"}
//                               src='https://placehold.co/48x48'
//                             />
//                             <Box
//                               sx={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontWeight: 600,
//                                   fontSize: "14px",
//                                   lineHeight: "21px",
//                                   color: Colors.white
//                                 }}
//                               >
//                                 Elmer Laverty
//                               </Typography>
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontSize: "12px",
//                                   lineHeight: "18px",
//                                   color: "rgba(255, 255, 255, 0.4)"
//                                 }}
//                               >
//                                 Okay, thanks
//                               </Typography>
//                             </Box>
//                             <ListItemText
//                               primary={"12m"}
//                               sx={{
//                                 m: 0,
//                                 "span": {
//                                   color: "rgba(255, 255, 255, 0.4)",
//                                   fontSize: "14px",
//                                   fontFamily: "Inter",
//                                   fontWeight: 500,
//                                   textAlign: "right"
//                                 }
//                               }}
//                             />
//                           </ListItemButton>
//                         </ListItem>
//                         <ListItem sx={{ p: 0 }}>
//                           <ListItemButton sx={{ px: 0, py: 2, gap: 2, alignItems: "flex-start" }}>
//                             <Avatar
//                               variant={"rounded"}
//                               src='https://placehold.co/48x48'
//                             />
//                             <Box
//                               sx={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontWeight: 600,
//                                   fontSize: "14px",
//                                   lineHeight: "21px",
//                                   color: Colors.white
//                                 }}
//                               >
//                                 Elmer Laverty
//                               </Typography>
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontSize: "12px",
//                                   lineHeight: "18px",
//                                   color: "rgba(255, 255, 255, 0.4)"
//                                 }}
//                               >
//                                 Okay, thanks
//                               </Typography>
//                             </Box>
//                             <ListItemText
//                               primary={"12m"}
//                               sx={{
//                                 m: 0,
//                                 "span": {
//                                   color: "rgba(255, 255, 255, 0.4)",
//                                   fontSize: "14px",
//                                   fontFamily: "Inter",
//                                   fontWeight: 500,
//                                   textAlign: "right"
//                                 }
//                               }}
//                             />
//                           </ListItemButton>
//                         </ListItem>
//                         <ListItem sx={{ p: 0 }}>
//                           <ListItemButton sx={{ px: 0, py: 2, gap: 2, alignItems: "flex-start" }}>
//                             <Avatar
//                               variant={"rounded"}
//                               src='https://placehold.co/48x48'
//                             />
//                             <Box
//                               sx={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontWeight: 600,
//                                   fontSize: "14px",
//                                   lineHeight: "21px",
//                                   color: Colors.white
//                                 }}
//                               >
//                                 Elmer Laverty
//                               </Typography>
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontSize: "12px",
//                                   lineHeight: "18px",
//                                   color: "rgba(255, 255, 255, 0.4)"
//                                 }}
//                               >
//                                 Okay, thanks
//                               </Typography>
//                             </Box>
//                             <ListItemText
//                               primary={"12m"}
//                               sx={{
//                                 m: 0,
//                                 "span": {
//                                   color: "rgba(255, 255, 255, 0.4)",
//                                   fontSize: "14px",
//                                   fontFamily: "Inter",
//                                   fontWeight: 500,
//                                   textAlign: "right"
//                                 }
//                               }}
//                             />
//                           </ListItemButton>
//                         </ListItem>
//                         <ListItem sx={{ p: 0 }}>
//                           <ListItemButton sx={{ px: 0, py: 2, gap: 2, alignItems: "flex-start" }}>
//                             <Avatar
//                               variant={"rounded"}
//                               src='https://placehold.co/48x48'
//                             />
//                             <Box
//                               sx={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontWeight: 600,
//                                   fontSize: "14px",
//                                   lineHeight: "21px",
//                                   color: Colors.white
//                                 }}
//                               >
//                                 Elmer Laverty
//                               </Typography>
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontSize: "12px",
//                                   lineHeight: "18px",
//                                   color: "rgba(255, 255, 255, 0.4)"
//                                 }}
//                               >
//                                 Okay, thanks
//                               </Typography>
//                             </Box>
//                             <ListItemText
//                               primary={"12m"}
//                               sx={{
//                                 m: 0,
//                                 "span": {
//                                   color: "rgba(255, 255, 255, 0.4)",
//                                   fontSize: "14px",
//                                   fontFamily: "Inter",
//                                   fontWeight: 500,
//                                   textAlign: "right"
//                                 }
//                               }}
//                             />
//                           </ListItemButton>
//                         </ListItem>
//                         <ListItem sx={{ p: 0 }}>
//                           <ListItemButton sx={{ px: 0, py: 2, gap: 2, alignItems: "flex-start" }}>
//                             <Avatar
//                               variant={"rounded"}
//                               src='https://placehold.co/48x48'
//                             />
//                             <Box
//                               sx={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontWeight: 600,
//                                   fontSize: "14px",
//                                   lineHeight: "21px",
//                                   color: Colors.white
//                                 }}
//                               >
//                                 Elmer Laverty
//                               </Typography>
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontSize: "12px",
//                                   lineHeight: "18px",
//                                   color: "rgba(255, 255, 255, 0.4)"
//                                 }}
//                               >
//                                 Okay, thanks
//                               </Typography>
//                             </Box>
//                             <ListItemText
//                               primary={"12m"}
//                               sx={{
//                                 m: 0,
//                                 "span": {
//                                   color: "rgba(255, 255, 255, 0.4)",
//                                   fontSize: "14px",
//                                   fontFamily: "Inter",
//                                   fontWeight: 500,
//                                   textAlign: "right"
//                                 }
//                               }}
//                             />
//                           </ListItemButton>
//                         </ListItem>
//                         <ListItem sx={{ p: 0 }}>
//                           <ListItemButton sx={{ px: 0, py: 2, gap: 2, alignItems: "flex-start" }}>
//                             <Avatar
//                               variant={"rounded"}
//                               src='https://placehold.co/48x48'
//                             />
//                             <Box
//                               sx={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontWeight: 600,
//                                   fontSize: "14px",
//                                   lineHeight: "21px",
//                                   color: Colors.white
//                                 }}
//                               >
//                                 Elmer Laverty
//                               </Typography>
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontSize: "12px",
//                                   lineHeight: "18px",
//                                   color: "rgba(255, 255, 255, 0.4)"
//                                 }}
//                               >
//                                 Okay, thanks
//                               </Typography>
//                             </Box>
//                             <ListItemText
//                               primary={"12m"}
//                               sx={{
//                                 m: 0,
//                                 "span": {
//                                   color: "rgba(255, 255, 255, 0.4)",
//                                   fontSize: "14px",
//                                   fontFamily: "Inter",
//                                   fontWeight: 500,
//                                   textAlign: "right"
//                                 }
//                               }}
//                             />
//                           </ListItemButton>
//                         </ListItem>
//                         <ListItem sx={{ p: 0 }}>
//                           <ListItemButton sx={{ px: 0, py: 2, gap: 2, alignItems: "flex-start" }}>
//                             <Avatar
//                               variant={"rounded"}
//                               src='https://placehold.co/48x48'
//                             />
//                             <Box
//                               sx={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontWeight: 600,
//                                   fontSize: "14px",
//                                   lineHeight: "21px",
//                                   color: Colors.white
//                                 }}
//                               >
//                                 Elmer Laverty
//                               </Typography>
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontSize: "12px",
//                                   lineHeight: "18px",
//                                   color: "rgba(255, 255, 255, 0.4)"
//                                 }}
//                               >
//                                 Okay, thanks
//                               </Typography>
//                             </Box>
//                             <ListItemText
//                               primary={"12m"}
//                               sx={{
//                                 m: 0,
//                                 "span": {
//                                   color: "rgba(255, 255, 255, 0.4)",
//                                   fontSize: "14px",
//                                   fontFamily: "Inter",
//                                   fontWeight: 500,
//                                   textAlign: "right"
//                                 }
//                               }}
//                             />
//                           </ListItemButton>
//                         </ListItem>
//                         <ListItem sx={{ p: 0 }}>
//                           <ListItemButton sx={{ px: 0, py: 2, gap: 2, alignItems: "flex-start" }}>
//                             <Avatar
//                               variant={"rounded"}
//                               src='https://placehold.co/48x48'
//                             />
//                             <Box
//                               sx={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontWeight: 600,
//                                   fontSize: "14px",
//                                   lineHeight: "21px",
//                                   color: Colors.white
//                                 }}
//                               >
//                                 Elmer Laverty
//                               </Typography>
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Inter",
//                                   fontSize: "12px",
//                                   lineHeight: "18px",
//                                   color: "rgba(255, 255, 255, 0.4)"
//                                 }}
//                               >
//                                 Okay, thanks
//                               </Typography>
//                             </Box>
//                             <ListItemText
//                               primary={"12m"}
//                               sx={{
//                                 m: 0,
//                                 "span": {
//                                   color: "rgba(255, 255, 255, 0.4)",
//                                   fontSize: "14px",
//                                   fontFamily: "Inter",
//                                   fontWeight: 500,
//                                   textAlign: "right"
//                                 }
//                               }}
//                             />
//                           </ListItemButton>
//                         </ListItem>
//                       </List>
//                     </Grid>
//                   </Grid>
//                 </Box>
//               </Box>
//             </Grid>
//             <Grid item md={8}>
//               <Box
//                 sx={{
//                   background: Colors.dark1,
//                   borderRadius: "16px"
//                 }}
//               >
//                 <Grid container>
//                   <Grid item md={12}
//                     sx={{
//                       borderBottom: `1px solid ${Colors.dark2}`
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "space-between",
//                         p: 2,
//                       }}
//                     >
//                     </Box>
//                   </Grid>
//                 </Grid>
//               </Box>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Fragment>
//   );
// }

// export default Chat;

import React, { Fragment, useEffect, useState } from 'react'
import { App as SendbirdApp } from '@sendbird/uikit-react';
import SendbirdChat from '@sendbird/chat';
import { GroupChannelModule, GroupChannelHandler } from '@sendbird/chat/groupChannel';
import '@sendbird/uikit-react/dist/index.css';
import BreadCrumb from '../../components/breadcrumb';
import "./index.css"
import Colors from '../../style';
import { Box } from '@mui/material';

const myColorSet = {
  "--sendbird-light-background-50": Colors.dark1,
  "--sendbird-light-onlight-01": Colors.white,
  "--sendbird-light-onlight-02": "rgba(255, 255, 255, 0.4)",
  "--sendbird-light-onlight-03": "rgba(255, 255, 255, 0.4)",
  "--sendbird-light-onlight-04": Colors.white + 33,
  "--sendbird-light-primary-300": Colors.buttonGradient,
  "--sendbird-light-background-100": Colors.primary,
  "--sendbird-light-primary-100": Colors.dark1,
}

function Chat() {
  const [sendbirdInstance, setSendbirdInstance] = useState(null);
  const app_Id = "D7E276CF-9D4A-4167-B5BF-57BE3C2CCB62";
  const user_Id = 'Admin';

  useEffect(() => {
    const connectSendbird = async () => {
      try {
        const sb = await SendbirdChat.init({
          appId: app_Id,
          localCacheEnabled: true,
          modules: [new GroupChannelModule()],
        });

        await sb.connect(user_Id);

        const channelHandler = new GroupChannelHandler({
          onMessageReceived: (channel, message) => {
            console.log('🚀 ~ file: ChatSupports.js:39 ~ messageNotification ~ message:', message);
            if (message) {
              console.log('🚀 ~ file: ChatSupports.js:34 ~ messageNotification ~ message:', message);
            }
          },
        });

        sb.groupChannel.addGroupChannelHandler(user_Id, channelHandler);
        setSendbirdInstance(sb);
        console.log('Sendbird connected successfully');
      } catch (error) {
        console.log('🚀 ~ file: Login.js:86 ~ onLogin ~ error:', error);
      }
    };

    connectSendbird();
    // setNotifyMessage(false);
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          borderBottom: "1px solid #4b4b4b"
        }}
      >
        <BreadCrumb
          pageName={"Chat Support"}
        />
      </Box>
      <SendbirdApp
        appId={app_Id}
        userId={user_Id}
        nickname={"Admin"}
        colorSet={myColorSet}
      />
    </Fragment>
  );
}

export default Chat;