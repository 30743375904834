import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Badge, Box, CardMedia, Checkbox, Grid, IconButton, ListItemText, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, Typography, Menu } from '@mui/material';
import BreadCrumb from '../../../components/breadcrumb';
import Colors from '../../../style';
import { CalendarIcon, ClockIcon, MarkerIcon, MemberIcon2, UserIcon, InfoIcon, MemberListIcon } from '../../../assets/icons';
import { Close, CloudUpload, Edit, MoreHoriz } from '@mui/icons-material';
import Images from '../../../assets/images';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import AdminServices from '../../../api/admin/AdminServices';
import { baseUrl } from '../../../../axios';
import { ErrorToaster, SuccessToaster } from '../../../components/toaster';
import { PageDot } from '../../../components/UI';
import Chip from '../../../components/chip';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { EditDateField, EditField, EditSelectField, EditTimeField } from '../../../components/inputfields';
import { EditButton, SecondaryButton } from '../../../components/buttons';

const ITEM_HEIGHT = 48;

const tableHead = [
  {
    name: "ID",
    sortOrder: { column: "member_id", direction: 'asc' },
    visible: true
  },
  {
    name: "Name",
    sortOrder: { column: "user", direction: 'asc' },
    visible: true
  },
  {
    name: "Contact",
    sortOrder: { column: "phone", direction: 'asc' },
    visible: true
  },
  {
    name: "Category",
    sortOrder: { column: "type", direction: 'asc' },
    visible: true
  },
  {
    name: "Date",
    sortOrder: { column: "created_at", direction: 'asc' },
    visible: true
  },
  {
    name: "Country",
    sortOrder: { column: "country", direction: 'asc' },
    visible: true
  },
  {
    name: "City",
    sortOrder: { column: "city", direction: 'asc' },
    visible: true
  },
  {
    name: "Action",
    sortOrder: { column: "action", direction: 'asc' },
    visible: true
  },
];

function OfferDetail() {
  const { state } = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [updatedImages, setUpdatedImages] = useState([]);
  const [updatedStartDate, setUpdatedStartDate] = useState(null);
  const [updatedEndDate, setUpdatedEndDate] = useState(null);
  const [updatedStartTime, setUpdatedStartTime] = useState(null);
  const [updatedEndTime, setUpdatedEndTime] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [secLoading, setSecLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(tableHead);
  const [anchor, setAnchor] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const startTimeMinutes = data?.start_time;
  const endTimeMinutes = data?.end_time;
  const startOfDay = moment().startOf('day');
  const startTime = startOfDay.clone().add(startTimeMinutes, 'minutes');
  const endTime = startOfDay.clone().add(endTimeMinutes, 'minutes');
  const duration = moment.duration(endTime.diff(startTime));
  const hours = Math.floor(duration.asHours());
  const { register, handleSubmit, setValue } = useForm();
  const inputRefs = useRef([]);

  const handleStatusApproved = async () => {
    setLoading(true);
    try {
      const obj = {
        offer_id: data?._id,
        is_approved: true,
        offer_name: data?.offer_name,
        business_user_id: data?.business_user_id
      };
      const result = await AdminServices.approveOffer(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusDisapproved = async () => {
    setSecLoading(true);
    try {
      const obj = {
        offer_id: data?._id,
        is_approved: false,
        offer_name: data?.offer_name,
        business_user_id: data?.business_user_id
      };
      const result = await AdminServices.approveOffer(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSecLoading(false);
    }
  };

  const getAvailedOffers = async () => {
    try {
      const result = await AdminServices.getAvailedOffers(id);
      if (result.responseCode == 200) {
        const shallowCopy = result?.data?.members?.map((item) => {
          const obj = { ...item };
          tableHead.forEach((value) => {
            if (value.name == "Action") {
              obj[value.sortOrder.column] = "action";
            } else if (value.name == "ID") {
              obj[value.sortOrder.column] = item.member[value.sortOrder.column];
            } else if (value.name == "Name") {
              const fullName = `${item[value.sortOrder.column].first_name} ${item[value.sortOrder.column].first_name}`
              obj[value.sortOrder.column] = fullName;
            } else if (value.name == "Contact") {
              obj[value.sortOrder.column] = item.user[value.sortOrder.column];
            } else if (value.name == "Category") {
              obj[value.sortOrder.column] = item[value.sortOrder.column]
            } else if (value.name == "Date") {
              obj[value.sortOrder.column] = moment(item.user[value.sortOrder.column]).format("DD MMM YYYY");
            } else if (value.name == "Country") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "City") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            }
          });
          obj.visible = true;
          return obj;
        })
        setRows(shallowCopy);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleToggleColumn = (column) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.name === column ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const handleSettingsClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchor(null);
  };

  const handleNavigate = (row) => {
    navigate(`/member-detail/${row.member_id}`, {
      state: {
        navigateFrom: "details"
      }
    })
  };

  const handleFileChange = async (file, ind) => {
    const formData = new FormData();
    formData.append("document", file);
    try {
      const result = await AdminServices.uploadImage(formData);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setUpdatedImages((prev) => {
          const updatedImagesCopy = [...prev];
          updatedImagesCopy[ind] = result?.data?.files[0]?.path; // Replace the image at the current index
          return updatedImagesCopy;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = () => {
    setIsEdit(!isEdit);
  };

  const setDefaultValues = () => {
    setValue("name", data?.offer_name);
    setValue("description", data?.offer_description);
    setValue("rules", data?.offer_rules);
    setValue("proposed_offer", data?.offer_member);
    setUpdatedStartDate(moment(data?.start_date));
    setUpdatedEndDate(moment(data?.end_date));
    setUpdatedStartTime(moment(data?.start_time, "HH:mm"));
    setUpdatedEndTime(moment(data?.end_time, "HH:mm"));
    setUpdatedImages(data?.image);
    setSelectedCategory(data?.category_offer[0]?.name);
  };

  const getAllCategories = async () => {
    try {
      const result = await AdminServices.getAllCategories(1, 50, "");
      if (result.responseCode == 200) {
        const filtered = result?.data?.categories.map((item) => ({
          id: item._id,
          name: item.name,
          type: item.type
        }))
        setCategories(filtered);
      }
    } catch (e) {
      console.log(e)
    }
  };

  const updateData = async (formData) => {
    const selected_type_id = categories.find((item) => item.name == selectedCategory)?._id
    const obj = {
      _id: id,
      offer_name: formData.name,
      offer_description: formData.description,
      offer_rules: formData.rules,
      offer_member: formData.proposed_offer,
      offer_type: selected_type_id,
      start_date: new Date(updatedStartDate),
      end_date: new Date(updatedEndDate),
      start_time: moment(updatedStartTime).format("HH:mm"),
      end_time: moment(updatedEndTime).format("HH:mm"),
      image: updatedImages
    }
    try {
      const result = await AdminServices.updateOffer(obj);
      if (result?.responseCode == 200) {
        SuccessToaster(result?.message);
        setIsEdit(false);
      }
    } catch (err) {
      ErrorToaster(err)
    } finally {
      fetchData()
    }
  };

  const fetchData = async () => {
    try {
      const result = await AdminServices.getOfferDetails(id);
      if (result.responseCode === 200) {
        setData(result?.data?.offers[0]);
        setIsActive(result?.data?.offers[0].is_active);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const openMap = () => {
    const url = `https://www.google.com/maps?q=${data?.establishment?.latitude},${data?.establishment?.longitude}`;
    window.open(url, '_blank');
  }

  useEffect(() => {
    let isMounted = true;

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    getAvailedOffers();
  }, [id]);

  useEffect(() => {
    setDefaultValues();
  }, [data]);

  useEffect(() => {
    getAllCategories();
  }, []);


  return (
    <Fragment>
      <Grid container alignItems={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb
            isActive={isActive}
            pageName={"Offer Detail"}
            status={{ data: data, state: state }}
            handleStatusApproved={handleStatusApproved}
            buttonProps={data?.is_approved ? true : false}
            handleStatusDisapproved={handleStatusDisapproved}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          background: Colors.dark3,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}
      >
        <Box
          component={"form"}
          onSubmit={handleSubmit(updateData)}
          sx={{
            background: Colors.dark1,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            borderRadius: "20px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "16px 24px 0px 24px",
              position: "relative"
            }}
          >
            <PageDot top={"30px"} left={0} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2
              }}
            >
              <UserIcon />
              <Typography
                sx={{
                  color: Colors.white,
                  fontWeight: 600,
                  fontSize: "18px"
                }}
              >
                Offer Detail
              </Typography>
              {!data?.is_approved && (
                <Fragment>
                  <Tooltip title="Edit">
                    <EditButton
                      onClick={handleClick}
                      isEdit={isEdit}
                    />
                  </Tooltip>
                  {isEdit && (
                    <SecondaryButton
                      type={"submit"}
                      title={"Update"}
                    />
                  )}
                </Fragment>
              )}
            </Box>
          </Box>
          <Grid
            container
            sx={{
              borderTop: `2px solid ${Colors.dark2}`
            }}
          >
            <Grid item md={3.75}
              sx={{
                borderRight: `2px solid ${Colors.dark2}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 3
                }}
              >
                <CardMedia
                  component={"img"}
                  src={baseUrl + data?.business?.company_logo}
                  // src={Images.businessLogo}
                  sx={{
                    width: "100%",
                    height: "250px",
                    objectFit: "cover",
                    borderRadius: "15px"
                  }}
                />
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Offer Name
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {data?.offer_name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Business Name
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {data?.business?.company_name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Brand Name
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {data?.brand?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Branch Name
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {data?.establishment?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                    onClick={openMap}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Location
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {data?.establishment?.address_1}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}
              sx={{
                borderRight: `2px solid ${Colors.dark2}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  height: "100%"
                }}
              >
                <Box
                  sx={{
                    borderBottom: `2px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: Colors.white,
                        fontWeight: 600
                      }}
                    >
                      Offer Name
                    </Typography>
                    {isEdit ? (
                      <EditField
                        register={register("name")}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: Colors.white,
                        }}
                      >
                        {data?.offer_name}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `2px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: Colors.white,
                        fontWeight: 600
                      }}
                    >
                      Offer Description
                    </Typography>
                    {isEdit ? (
                      <EditField
                        register={register("description")}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: Colors.white,
                        }}
                      >
                        {data?.offer_description}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `2px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: Colors.white,
                        fontWeight: 600
                      }}
                    >
                      Offer Rules
                    </Typography>
                    {isEdit ? (
                      <EditField
                        register={register("rules")}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: Colors.white,
                        }}
                      >
                        {data?.offer_rules}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `2px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: Colors.white,
                        fontWeight: 600
                      }}
                    >
                      Offer Members
                    </Typography>
                    {isEdit ? (
                      <EditField
                        register={register("proposed_offer")}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: Colors.white,
                        }}
                      >
                        {data?.offer_member}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `2px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: Colors.white,
                        fontWeight: 600
                      }}
                    >
                      Offer Type
                    </Typography>
                    {isEdit ? (
                      <EditSelectField
                        select={true}
                        value={selectedCategory}
                        register={register("offer_type", {
                          onChange: (e) => {
                            const value = e.target.value;
                            setSelectedCategory(value);
                          }
                        })}
                        options={categories.filter((item) => item.type == "Events & Offers")}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: Colors.white,
                        }}
                      >
                        {data?.category_offer?.map(item => item.name)?.join(", ")}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    p: "20px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      borderBottom: `1px solid ${Colors.dark2}`,
                    }}
                  >
                    <Box
                      sx={{
                        width: isEdit ? "10%" : "30px",
                        height: "30px",
                        borderRadius: "50%",
                        background: isEdit ? "transparent" : Colors.dark3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CalendarIcon />
                    </Box>
                    <Box
                      sx={{
                        py: 1,
                        width: isEdit ? "90%" : "auto"
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.dark2,
                          fontSize: "10px"
                        }}
                      >
                        Start Date
                      </Typography>
                      {isEdit ? (
                        <EditDateField
                          value={updatedStartDate}
                          onChange={(e) => setUpdatedStartDate(e)}
                        />
                      ) : (
                        <Typography
                          variant='body2'
                          sx={{
                            color: Colors.white
                          }}
                        >
                          {moment(data?.start_date).format("DD MMM YYYY")}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      borderBottom: `1px solid ${Colors.dark2}`,
                    }}
                  >
                    <Box
                      sx={{
                        width: isEdit ? "10%" : "30px",
                        height: "30px",
                        borderRadius: "50%",
                        background: isEdit ? "transparent" : Colors.dark3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CalendarIcon />
                    </Box>
                    <Box
                      sx={{
                        py: 1,
                        width: isEdit ? "90%" : "auto"
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.dark2,
                          fontSize: "10px"
                        }}
                      >
                        End Date
                      </Typography>
                      {isEdit ? (
                        <EditDateField
                          value={updatedEndDate}
                          onChange={(e) => setUpdatedEndDate(e)}
                        />
                      ) : (
                        <Typography
                          variant='body2'
                          sx={{
                            color: Colors.white
                          }}
                        >
                          {moment(data?.end_date).format("DD MMM YYYY")}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      borderBottom: `1px solid ${Colors.dark2}`,
                    }}
                  >
                    <Box
                      sx={{
                        width: isEdit ? "10%" : "30px",
                        height: "30px",
                        borderRadius: "50%",
                        background: isEdit ? "transparent" : Colors.dark3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ClockIcon />
                    </Box>
                    <Box
                      sx={{
                        py: 1,
                        width: isEdit ? "90%" : "auto"
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.dark2,
                          fontSize: "10px"
                        }}
                      >
                        Time
                      </Typography>
                      {isEdit ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1
                          }}
                        >
                          <EditTimeField
                            value={updatedStartTime}
                          />
                          <EditTimeField
                            value={updatedEndTime}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant='body2'
                          sx={{
                            color: Colors.white
                          }}
                        >
                          {`
                          ${startTime.format("hh:mm A")} - ${endTime.format("hh:mm A")} - ${hours.toFixed(2)} ${hours > 1 ? "Hrs" : "Hr"}
                        `}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4.25}>
              <Grid container sx={{ p: 2, rowGap: 2 }} columnSpacing={2}>
                {updatedImages?.map((item, ind) => (
                  <Grid
                    key={ind}
                    item
                    md={ind === 0 ? 12 : 6}
                    sx={{ position: "relative" }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        "&:hover .overlay": {
                          opacity: isEdit ? 1 : 0, // Apply hover effect only when isEdit is true
                        },
                      }}
                    >
                      <CardMedia
                        component={"img"}
                        src={baseUrl + item}
                        sx={{
                          width: "100%",
                          height: ind === 0 ? "250px" : "150px",
                          objectFit: "cover",
                          borderRadius: "15px",
                        }}
                      />
                      <Box
                        className="overlay"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          borderRadius: "15px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          opacity: 0, // Default to hidden
                          transition: "opacity 0.3s ease",
                        }}
                      >
                        {isEdit && (
                          <label>
                            <input
                              ref={(input) => (inputRefs.current[ind] = input)}
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }} // Hide the input
                              onChange={(e) => handleFileChange(e.target.files[0], ind)}
                            />
                            <IconButton
                              onClick={() => inputRefs.current[ind].click()}
                              sx={{
                                border: `1px solid ${Colors.secondary}`
                              }}
                            >
                              <CloudUpload sx={{ color: Colors.secondary }} />
                            </IconButton>
                          </label>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {state?.navigateFrom == "list" && (
          <Box
            sx={{
              background: Colors.dark3,
            }}
          >
            <Box
              sx={{
                background: Colors.dark1,
                p: "16px 24px 8px 24px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                borderRadius: "20px",
                height: "calc(100vh - 293px)"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  position: "relative"
                }}
              >
                <PageDot top={"13px"} left={"-24px"} />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2
                  }}
                >
                  <MemberListIcon />
                  <Typography sx={{ color: Colors.white }}>
                    Offer Availed By Members
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1
                  }}
                >
                  {/* <IconButton
                sx={{
                  background: Colors.buttonGradient
                }}
                onClick={() => handleDownload()}
              >
                <SaveAlt sx={{ color: Colors.white }} />
              </IconButton> */}
                  <IconButton
                    onClick={handleSettingsClick}
                  >
                    <MoreHoriz sx={{ color: Colors.white }} />
                  </IconButton>
                </Box>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchor}
                  open={Boolean(anchor)}
                  onClose={handleSettingsClose}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        background: Colors.dark3,
                        color: Colors.white,
                        overflow: 'visible',
                        '&::before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 15,
                          width: 10,
                          height: 10,
                          bgcolor: Colors.dark3,
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 1,
                        },
                        ".MuiList-root": {
                          p: 0
                        }
                      }
                    }
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Box
                    component={"div"}
                    sx={{ maxHeight: ITEM_HEIGHT * 4.5, overflowY: 'auto' }}
                  >
                    {columns.map((col) => (
                      <MenuItem key={col.name}>
                        <Checkbox
                          sx={{
                            color: `${Colors.primary} !important`
                          }}
                          checked={col.visible}
                          onChange={() => handleToggleColumn(col.name)}
                        />
                        <ListItemText
                          primary={col.name}
                        />
                      </MenuItem>
                    ))}
                  </Box>
                </Menu>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{
                      background: Colors.dark3
                    }}
                  >
                    <TableRow>
                      {columns.filter((cell) => cell.visible).map((cell, ind, visibleColumns) => (
                        <TableCell
                          key={cell}
                          sx={{
                            p: "10px 16px",
                            display: "table-cell",
                            borderBottom: "none",
                            borderRadius: ind == 0
                              ? "20px 0 0 20px"
                              : ind == visibleColumns.length - 1
                                ? "0 20px 20px 0"
                                : "none",
                          }}
                        >
                          {cell.name != "Action" ? (
                            <TableSortLabel
                              active={cell.sortOrder.column}
                              direction={cell.sortOrder.direction}
                              // onClick={() => handleSort(cell.sortOrder.column)}
                              sx={{
                                "svg": {
                                  fontSize: "12px",
                                  color: `${Colors.white} !important`,
                                }
                              }}
                            >
                              <Box
                                sx={{
                                  color: Colors.white,
                                }}
                              >
                                {cell.name}
                              </Box>
                            </TableSortLabel>
                          ) : (
                            <Box
                              sx={{
                                color: Colors.white,
                              }}
                            >
                              {cell.name}
                            </Box>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row, ind) => (
                      <TableRow key={ind}>
                        {columns?.map((col, colInd) => col.visible && (
                          col.name == "Action" ? (
                            <TableCell key={colInd}
                              sx={{
                                p: "8px 16px"
                              }}
                            >
                              <IconButton
                                sx={{
                                  color: Colors.white
                                }}
                                onClick={() => handleNavigate(row)}
                              >
                                <MoreHoriz />
                              </IconButton>
                            </TableCell>
                          ) : col.name == "Name" ? (
                            <TableCell
                              key={colInd}
                              sx={{
                                color: Colors.white,
                                p: "8px 16px"
                              }}
                            >
                              <Tooltip title={row[col.sortOrder.column]}>
                                {row[col.sortOrder.column].length > 8
                                  ? row[col.sortOrder.column].slice(0, 8) + "..."
                                  : row[col.sortOrder.column]
                                }
                              </Tooltip>
                            </TableCell>
                          ) : col.name == "Category" ? (
                            <TableCell
                              key={colInd}
                              sx={{
                                color: Colors.white,
                                p: "8px 16px"
                              }}
                            >
                              <Tooltip
                                title={row[col.sortOrder.column].join(", ")}
                              >
                                {row[col.sortOrder.column].length > 1 ? (
                                  <Badge
                                    badgeContent={`+${row[col.sortOrder.column].length - 1}`}
                                    sx={{
                                      ".MuiBadge-badge": {
                                        background: Colors.buttonGradient,
                                        right: "-15px"
                                      }
                                    }}
                                  >
                                    {row[col.sortOrder.column][0].name}
                                  </Badge>
                                ) : row[col.sortOrder.column][0].name}
                              </Tooltip>
                            </TableCell>
                          ) : (
                            <TableCell
                              key={colInd}
                              sx={{
                                color: Colors.white,
                                p: "8px 16px"
                              }}
                            >
                              {row[col.sortOrder.column]}
                            </TableCell>
                          )
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}
      </Box>
    </Fragment>
  )
}

export default OfferDetail;