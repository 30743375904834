import React, { Fragment, useEffect, useState } from 'react';
import { Box, ButtonGroup, Checkbox, Chip, Grid, IconButton, ListItemText, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material';
import Colors from '../../../style';
import BreadCrumb from '../../../components/breadcrumb';
import AdminServices from '../../../api/admin/AdminServices';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowLeft, KeyboardArrowRight, MoreHoriz, SaveAlt } from '@mui/icons-material';
import { PageDot } from '../../../components/UI';
import { MemberListIcon } from '../../../assets/icons';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import moment from 'moment';
import Filter from '../../../components/filter';
import { InputField, AutoCompleteField } from '../../../components/inputfields';
import { PrimaryButton, PrimaryIconButton } from '../../../components/buttons';

const ITEM_HEIGHT = 48;

const tableHead = [
  {
    name: "Sr.",
    sortOrder: { column: "Sr.", direction: 'asc' },
    visible: true
  },
  {
    name: "Name",
    sortOrder: { column: "first_name", direction: 'asc' },
    visible: true
  },
  {
    name: "Contact",
    sortOrder: { column: "phone", direction: 'asc' },
    visible: true
  },
  {
    name: "Email",
    sortOrder: { column: "email", direction: 'asc' },
    visible: true
  },
  {
    name: "Date",
    sortOrder: { column: "created_at", direction: 'asc' },
    visible: true
  },
  {
    name: "Country",
    sortOrder: { column: "country", direction: 'asc' },
    visible: true
  },
  {
    name: "City",
    sortOrder: { column: "city", direction: 'asc' },
    visible: true
  },
  {
    name: "Status",
    sortOrder: { column: "is_active", direction: 'asc' },
    visible: true
  },
  {
    name: "Action",
    sortOrder: { column: "action", direction: 'asc' },
    visible: true
  },
];

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBack = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNext = (event) => {
    onPageChange(event, page + 1);
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <ButtonGroup
        sx={{
          borderRadius: 2,
          background: Colors.dark1,
        }}
      >
        <IconButton
          onClick={handleBack}
          disabled={page == 0}
          aria-label="previous page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNext}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </ButtonGroup>
    </Box>
  )
}

function AdminList() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(tableHead);
  const [anchor, setAnchor] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [search, setSearch] = useState(null);
  const navigate = useNavigate();

  const handleToggleColumn = (column) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.name === column ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const handleSort = (column) => {
    setColumns((prevColumns) => {
      const newColumns = prevColumns.map((col) =>
        col.sortOrder.column === column
          ? { ...col, sortOrder: { column, direction: col.sortOrder.direction === 'asc' ? 'desc' : 'asc' } }
          : col
      );

      const sortedRows = [...rows].sort((a, b) => {
        const col = newColumns.find(col => col.sortOrder.column === column);
        const direction = col.sortOrder.direction;
        if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
        if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
        return 0;
      });

      setRows(sortedRows);
      return newColumns;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getSubAdmins(newPage + 1, limit);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    getSubAdmins(page, event.target.value);
  };

  const handleSettingsClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchor(null);
  };

  const getSubAdmins = async (page, limit, city_id, search) => {
    try {
      const result = await AdminServices.getSubAdmins(
        page ? page : 1,
        limit ? limit : 10,
        city_id ? city_id : "",
        search ? search : ""
      );
      if (result.responseCode == 200) {
        setCount(result?.data.totalSub_AdminsMemberProfiles);
        const shallowCopy = result?.data?.Sub_Admins?.map((item, ind) => {
          const obj = { ...item };
          tableHead.forEach((value) => {
            if (value.name == "Action") {
              obj[value.sortOrder.column] = "action";
            } else if (value.name == "Sr.") {
              // obj[value.sortOrder.column] = item[value.sortOrder.column];
              obj[value.sortOrder.column] = ind + 1;
            } else if (value.name == "Name") {
              obj[value.sortOrder.column] = item[value.sortOrder.column] ?? "-";
            } else if (value.name == "Contact") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Email") {
              obj[value.sortOrder.column] = item[value.sortOrder.column]
            } else if (value.name == "Date") {
              obj[value.sortOrder.column] = moment(item[value.sortOrder.column]).format("DD MMM YYYY");
            } else if (value.name == "Country") {
              obj[value.sortOrder.column] = item?.city?.country_name;
            } else if (value.name == "City") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Status") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            }
          });
          obj.visible = true;
          return obj;
        })
        setRows(shallowCopy);
      }
    } catch (e) {
      console.log(e)
    }
  };

  const handleNavigate = (row) => {
    navigate(`/update-admin/${row._id}`, { state: row })
  };

  const handleDownload = () => {
    const head = tableHead.filter((item) => item.name != "Action").map((item) => item.name);
    const data = rows.map(item => [
      item.admin_id,
      item.name,
      item.phone,
      item.email,
      item.created_at,
      item.city,
      item.is_active ? "Active" : "Inactive"
    ]);

    const ws = XLSX.utils.aoa_to_sheet([head, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Convert the workbook to an array buffer
    const buf = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the file using FileSaver.js
    saveAs(new Blob([buf]), "admins.xlsx");
  };

  const getCountries = async () => {
    try {
      const result = await AdminServices.getCountries();
      if (result.responseCode == 200) {
        setCountries(result.data.countries);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCities = async (id) => {
    try {
      const result = await AdminServices.getCities(id);
      if (result.responseCode == 200) {
        setCities(result.data.cities);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = () => {
    setSearch("");
    setSelectedCountry(null)
    setSelectedCity(null)
    getSubAdmins(page, limit, "");
  };

  useEffect(() => {
    getSubAdmins();
    getCountries();
  }, []);

  return (
    <Fragment>
      <Grid container alignItems={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb pageName={"Admin List"} />
        </Grid>
        <Grid item md={11.3}>
          <Box
            sx={{
              p: 2
            }}
          >
            <Filter
              onClick={handleReset}
              items={
                [
                  <Grid item md={4.2}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        width: "100%"
                      }}
                    >
                      <InputField
                        value={search}
                        fullWidth={true}
                        placeholder={"Search"}
                        type={"text"}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value != "") {
                            setSearch(value);
                          } else {
                            setSearch(null);
                          }
                        }}
                      />
                    </Box>
                  </Grid>,

                  <Grid item md={3.35}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <AutoCompleteField
                      value={selectedCountry}
                      fullWidth={true}
                      select={true}
                      options={countries}
                      placeholder={"Country"}
                      onChange={(e, newValue) => {
                        setSelectedCountry(newValue);
                        getCities(newValue.country_id);
                      }}
                    />
                  </Grid>,

                  <Grid item md={3.35}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <AutoCompleteField
                      disabled={selectedCountry == null ? true : false}
                      value={selectedCity}
                      fullWidth={true}
                      select={true}
                      options={cities}
                      placeholder={"City"}
                      onChange={(e, newValue) => {
                        setSelectedCity(newValue);
                      }}
                    />
                  </Grid>,
                ]
              }
            />
          </Box>
        </Grid>
        <Grid item md={0.7}>
          <Box
            sx={{ py: 2, pr: 2 }}
          >
            <PrimaryIconButton
              title={"Search"}
              onClick={() => getSubAdmins("", "", selectedCity?._id, search)}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          background: Colors.dark3,
          p: "0px 16px"
        }}
      >
        <Box
          sx={{
            background: Colors.dark1,
            p: "16px 24px 8px 24px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            borderRadius: "20px",
            height: "calc(100vh - 293px)"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative"
            }}
          >
            <PageDot top={"13px"} left={"-24px"} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2
              }}
            >
              <MemberListIcon />
              <Typography sx={{ color: Colors.white }}>
                Admins
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1
              }}
            >
              <IconButton
                sx={{
                  background: Colors.buttonGradient
                }}
                onClick={() => handleDownload()}
              >
                <SaveAlt sx={{ color: Colors.white }} />
              </IconButton>
              <IconButton
                onClick={handleSettingsClick}
              >
                <MoreHoriz sx={{ color: Colors.white }} />
              </IconButton>
            </Box>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={handleSettingsClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    background: Colors.dark3,
                    color: Colors.white,
                    overflow: 'visible',
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 15,
                      width: 10,
                      height: 10,
                      bgcolor: Colors.dark3,
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 1,
                    },
                    ".MuiList-root": {
                      p: 0
                    }
                  }
                }
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box
                component={"div"}
                sx={{ maxHeight: ITEM_HEIGHT * 4.5, overflowY: 'auto' }}
              >
                {columns.map((col) => (
                  <MenuItem key={col.name}>
                    <Checkbox
                      sx={{
                        color: `${Colors.primary} !important`
                      }}
                      checked={col.visible}
                      onChange={() => handleToggleColumn(col.name)}
                    />
                    <ListItemText
                      primary={col.name}
                    />
                  </MenuItem>
                ))}
              </Box>
            </Menu>
          </Box>
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  background: Colors.dark3
                }}
              >
                <TableRow>
                  {columns.filter((cell) => cell.visible).map((cell, ind, visibleColumns) => (
                    <TableCell
                      sx={{
                        p: "10px 16px",
                        display: "table-cell",
                        borderBottom: "none",
                        borderRadius: ind == 0
                          ? "20px 0 0 20px"
                          : ind == visibleColumns.length - 1
                            ? "0 20px 20px 0"
                            : "none",
                      }}
                    >
                      {cell.name != "Action" ? (
                        <TableSortLabel
                          active={cell.sortOrder.column}
                          direction={cell.sortOrder.direction}
                          onClick={() => handleSort(cell.sortOrder.column)}
                          sx={{
                            "svg": {
                              fontSize: "12px",
                              color: `${Colors.white} !important`,
                            },
                            width: (cell.name == "Date" || cell.name == "Country" || cell.name == "Name") && "100px"
                          }}
                        >
                          <Box
                            sx={{
                              color: Colors.white,
                            }}
                          >
                            {cell.name}
                          </Box>
                        </TableSortLabel>
                      ) : (
                        <Box
                          sx={{
                            color: Colors.white,
                          }}
                        >
                          {cell.name}
                        </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, ind) => (
                  <TableRow key={ind}>
                    {columns?.map((col, colInd) => col.visible && (
                      col.name == "Action" ? (
                        <TableCell key={colInd}
                          sx={{
                            p: "8px 16px"
                          }}
                        >
                          <IconButton
                            sx={{
                              color: Colors.white
                            }}
                            onClick={() => handleNavigate(row)}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </TableCell>
                      ) : col.name == "Name" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          <Tooltip title={row[col.sortOrder.column]}>
                            {row[col.sortOrder.column].length > 8
                              ? row[col.sortOrder.column].slice(0, 8) + "..."
                              : row[col.sortOrder.column]
                            }
                          </Tooltip>
                        </TableCell>
                      ) : col.name == "Email" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          <Tooltip title={row[col.sortOrder.column]}>
                            {row[col.sortOrder.column].length > 12
                              ? row[col.sortOrder.column].slice(0, 12) + "..."
                              : row[col.sortOrder.column]
                            }
                          </Tooltip>
                        </TableCell>
                      ) : col.name == "City" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          {row[col.sortOrder.column]?.name}
                        </TableCell>
                      ) : col.name == "Status" ? (
                        <TableCell key={colInd}
                          sx={{
                            p: "8px 16px"
                          }}
                        >
                          <Chip
                            label={
                              row.is_active ? "Active" : "Inactive"
                            }
                            sx={{
                              background: row.is_active ? Colors.success + 19 : Colors.danger + 19,
                              color: row.is_active ? Colors.success : Colors.danger,
                            }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          {row[col.sortOrder.column]}
                        </TableCell>
                      )
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Table>
        <TablePagination
          sx={{
            borderBottom: "none",
            borderRadius: "12px",
            color: Colors.white,
            ".MuiTablePagination-spacer": {
              flex: 1,
            },
            ".MuiToolbar-root": {
              gap: "12px",
              paddingRight: "24px",
              ".MuiInputBase-root": {
                mr: 1,
              }
            },
          }}
          slotProps={{
            select: {
              MenuProps: {
                slotProps: {
                  paper: {
                    sx: {
                      background: Colors.dark3
                    }
                  },
                },
                MenuListProps: {
                  sx: {
                    color: Colors.white,
                    "li": {
                      ":hover": {
                        background: Colors.buttonGradient
                      }
                    }
                  },
                },
              }
            }
          }}
          rowsPerPageOptions={[20, 50, 100]}
          colSpan={12}
          count={count}
          rowsPerPage={limit}
          page={page}
          labelRowsPerPage={"Showing"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Table>
    </Fragment>
  )
}

export default AdminList