import React, { Fragment, useState, useEffect } from 'react';
import BreadCrumb from '../../components/breadcrumb';
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  TablePagination,
  ButtonGroup,
  TableSortLabel,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  Button,
  Autocomplete,
  TextField,
  List,
  Tooltip,
} from '@mui/material';
import { MoreHoriz, KeyboardArrowLeft, KeyboardArrowRight, ArrowDropDown, CloseRounded } from '@mui/icons-material';
import Colors from '../../style';
import Filter from '../../components/filter';
import { PrimaryButton } from '../../components/buttons';
import { BusinessIcon2 } from '../../assets/icons';
import { DateField, InputField, InputSelectField } from '../../components/inputfields';
import { useNavigate } from 'react-router-dom';
import { PageDot } from '../../components/UI';
import AdminServices from '../../api/admin/AdminServices';
import { useForm } from 'react-hook-form';
import { SuccessToaster } from '../../components/toaster';
import moment from 'moment';

const ITEM_HEIGHT = 48;

const types = ["Member", "Business", "Member & Business"];

const tableHead = [
  {
    name: "ID",
    sortOrder: { column: "cust_noti_id", direction: 'asc' },
    visible: true
  },
  {
    name: "Time",
    sortOrder: { column: "created_at", direction: 'asc' },
    visible: true
  },
  {
    name: "Date",
    sortOrder: { column: "created_at", direction: 'asc' },
    visible: true
  },
  {
    name: "Title",
    sortOrder: { column: "title", direction: 'asc' },
    visible: true
  },
  {
    name: "Content",
    sortOrder: { column: "content", direction: 'asc' },
    visible: true
  },
  {
    name: "Action",
    sortOrder: { column: "Action", direction: 'asc' },
    visible: true
  },
];

function MultiSelect({ data, selectedType, handleScroll, selected, setSelected }) {

  useEffect(() => {
    // Select all by default when the component mounts if data is not empty
    if (data.length > 0) {
      setSelected(data);
    }
  }, [data]);

  const handleToggleItem = (item) => {
    if (item != null && item.id) {
      setSelected((prevSelected) =>
        prevSelected.some(selectedItem => selectedItem?.id === item.id)
          ? prevSelected.filter(selectedItem => selectedItem?.id !== item.id)
          : [...prevSelected, item]
      );
    }
  };

  return (
    <Autocomplete
      multiple
      options={data}
      value={selected}
      disableClearable
      disableCloseOnSelect
      id="multi-select-autocomplete"
      getOptionLabel={(option) => option.name}
      slotProps={{
        paper: {
          sx: {
            background: Colors.dark3,
            color: Colors.white,
            borderRadius: '15px',
          }
        }
      }}
      renderOption={(props, option, { selected }) => (
        <Fragment>
          <MenuItem {...props} value={selected} onClick={() => handleToggleItem(option)}>
            <Checkbox
              checked={selected}
              sx={{
                color: `${Colors.primary} !important`,
              }}
            />
            {option.name}
          </MenuItem>
        </Fragment>
      )}
      renderTags={(selected) => {
        const chipsToShow = selected.length > 3 ? selected.slice(0, 3) : selected;
        const extraCount = selected.length - 3;
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {chipsToShow.map((option, index) => (
              <Chip
                key={option.id}
                label={option.name}
                sx={{
                  background: Colors.primary,
                  color: Colors.white,
                }}
              />
            ))}
            {selected.length > 3 && (
              <Chip
                label={`+${extraCount}`}
                sx={{
                  background: Colors.primary,
                  color: Colors.white,
                }}
              />
            )}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Select ${selectedType}`}
          variant="outlined"
          InputLabelProps={{
            sx: {
              color: Colors.white,
              "&.Mui-focused": {
                color: Colors.white,
              },
            },
          }}
          sx={{
            "input": {
              color: Colors.white,
            },
            "& .MuiAutocomplete-root": {
              borderColor: Colors.white,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.white,
              borderRadius: "15px",
              color: Colors.white,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.white,
              borderWidth: '1px',
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: `${Colors.white} !important`,
              borderWidth: '1px !important',
            },
            "&.Mui-focused": {
              "fieldset": {
                borderColor: `${Colors.white} !important`,
                borderWidth: '1px !important',
              }
            },
            ":hover": {
              "fieldset": {
                borderColor: `${Colors.white} !important`,
                borderWidth: '1px !important',
              }
            },
            'svg': {
              color: Colors.white,
            },
          }}
        />
      )}
      ListboxComponent={(listboxProps) => (
        <List component={"ul"} {...listboxProps} onScroll={handleScroll}>
          <MenuItem>
            <Button
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setSelected(data);
              }}
              sx={{
                borderColor: Colors.primary,
                color: Colors.primary,
                textTransform: "capitalize",
                marginRight: 1,
                ":hover": {
                  borderColor: Colors.secondary,
                  color: Colors.secondary,
                }
              }}
            >
              Select All
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setSelected([]);
              }}
              sx={{
                borderColor: Colors.primary,
                color: Colors.primary,
                textTransform: "capitalize",
                ":hover": {
                  borderColor: Colors.secondary,
                  color: Colors.secondary,
                }
              }}
            >
              Unselect All
            </Button>
          </MenuItem>
          {listboxProps.children}
        </List>
      )}
    />
  )
};

function NotificationDialog({ open, handleClose }) {
  const [selectedType, setSelectedType] = useState(null);
  const [members, setMembers] = useState([]);
  const [business, setBusiness] = useState([]);
  const [memberAndBusiness, setMemberAndBusiness] = useState([]);
  const [selected, setSelected] = useState([]);
  const [titleNotification, setTitleNotification] = useState("");
  const [messageNotification, setMessageNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const TITLE_MAX_CHARACTER = 35;
  const MESSAGE_MAX_CHARACTER = 108;

  let remainingTitleChars = TITLE_MAX_CHARACTER;
  let remainingMessageChars = MESSAGE_MAX_CHARACTER;

  const getAllMembers = async () => {
    try {
      const result = await AdminServices.getAllMembers(page, 50, "accepted", "", "", "", "", "");
      if (result.responseCode == 200) {
        setCount(result?.data?.totalMemberProfiles);
        const copy = result?.data?.MemberProfile?.map((item) => ({
          id: item?.user_id,
          name: item?.user
        }));
        // setMembers(copy);
        if (members.length != count) {
          setMembers((prevMembers) => [...prevMembers, ...copy]); // Append new data to existing state
          setPage((prevPage) => prevPage + 1); // Increment page number for next fetch
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllBusiness = async () => {
    try {
      const result = await AdminServices.getAllBusiness(page, 50, "accepted", "", "", "", "");
      if (result.responseCode == 200) {
        setCount(result?.data?.totalBusinessProfiles);
        const copy = result?.data?.BusinessProfile?.map((item) => ({
          id: item?.user_id,
          name: item?.company_name
        }));
        // setBusiness(copy);
        if (business.length != count) {
          setBusiness((prevBusiness) => [...prevBusiness, ...copy]); // Append new data to existing state
          setPage((prevPage) => prevPage + 1);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMemberAndBusiness = async () => {
    const array = [];
    try {
      const memberResult = await AdminServices.getAllMembers(page, 25, "accepted", "", "", "", "", "");
      const businessResult = await AdminServices.getAllBusiness(page, 25, "accepted", "", "", "", "");
      if (memberResult.responseCode == 200 && businessResult.responseCode == 200) {
        setCount(memberResult?.data?.totalMemberProfiles + businessResult?.data?.totalBusinessProfiles);
        memberResult?.data?.MemberProfile?.forEach((ele) => {
          const obj = {
            id: ele.user_id,
            name: ele.user
          };
          array.push(obj);
        });
        businessResult?.data?.BusinessProfile?.forEach((ele) => {
          const obj = {
            id: ele.user_id,
            name: ele.company_name,
          };
          array.push(obj);
        });
        if (memberAndBusiness.length != count) {
          setMemberAndBusiness((prevArr) => [...prevArr, ...array]);
          setPage((prevPage) => prevPage + 1);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleScroll = (event) => {
    const listbox = event.target;
    if (listbox.scrollTop + listbox.clientHeight === listbox.scrollHeight) {
      // Reached bottom of list, fetch more data
      if (selectedType === "Member") {
        getAllMembers();
      } else if (selectedType === "Business") {
        getAllBusiness();
      } else {
        handleMemberAndBusiness();
      }
    }
  };

  const handleFormSubmit = async (formData) => {
    setLoading(true)
    const obj = {
      title: formData.title,
      content: formData.content,
      userIds: selected.map((item) => item.id),
    };
    try {
      const result = await AdminServices.sendNotifications(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        reset();
        setSelected([]);
        handleClose();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      component={"form"}
      onSubmit={handleSubmit(handleFormSubmit)}
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(8px)",
            backgroundColor: "transparent",
          }
        }
      }}
      PaperProps={{
        sx: {
          border: `4px solid ${Colors.dark2}`,
          borderRadius: "50px",
          background: Colors.dark1,
          color: Colors.white,
          p: 3,
          maxWidth: "550px"
        },
      }}
      sx={{
        ".MuiDialogContent-root": {
          pt: "8px !important",
          pb: "0px important"
        }
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontSize: "24px",
          fontWeight: 600
        }}
      >
        Create New Notification
        <DialogContentText
          sx={{
            fontSize: "16px",
            color: Colors.white,
            textAlign: "center",
            opacity: 0.5
          }}
        >
          Fill the following details
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Box>
              <InputSelectField
                value={selectedType}
                select={true}
                type={"text"}
                variant={"outlined"}
                fullWidth={true}
                label={"Select Type"}
                data={types}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value == "Member") {
                    getAllMembers(value);
                    setSelectedType(value);
                  } else if (value == "Business") {
                    getAllBusiness(value);
                    setSelectedType(value);
                  } else {
                    setSelectedType(value);
                    handleMemberAndBusiness();
                  }
                }}
              />
            </Box>
          </Grid>
          {selectedType && (
            <Grid item md={12}>
              <Box>
                <MultiSelect
                  data={
                    selectedType == "Member" && members.length > 0 ?
                      members : selectedType == "Business" && business.length ?
                        business : selectedType == "Member & Business" && memberAndBusiness.length > 0 ?
                          memberAndBusiness : []
                  }
                  selectedType={selectedType}
                  handleScroll={handleScroll}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>
            </Grid>
          )}
          <Grid item md={12}>
            <InputSelectField
              register={register("title", {
                required: "Enter Title",
                onChange: (e) => setTitleNotification(e.target.value),
              })}
              fullWidth={true}
              label={"Title"}
              inputProps={{ maxLength: TITLE_MAX_CHARACTER }}
              // onChange={(e) => setTitleNotification(e.target.value)}
              error={errors?.title && true}
              helperText={errors?.title?.message ?
                errors?.title?.message :
                `${titleNotification.length} / ${remainingTitleChars}`
              }
            />
          </Grid>
          <Grid item md={12}>
            <InputSelectField
              register={register("content", {
                required: "Enter Message",
                onChange: (e) => setMessageNotification(e.target.value),
              })}
              fullWidth={true}
              select={false}
              multiline={true}
              rows={4}
              label={"Enter Your Message"}
              inputProps={{ maxLength: MESSAGE_MAX_CHARACTER }}
              // onChange={(e) => setMessageNotification(e.target.value)}
              error={errors?.content && true}
              helperText={errors?.content?.message ?
                errors?.content?.message :
                `${messageNotification.length} / ${remainingMessageChars}`
              }
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "30px",
            right: "30px",
            width: "30px",
            height: "30px",
            background: Colors.secondary,
            borderRadius: "50%",
          }}
        >
          <IconButton
            onClick={() => {
              handleClose();
              reset();
              setSelectedType("");
              setMembers([]);
              setBusiness([]);
              setMemberAndBusiness([]);
            }}
          >
            <CloseRounded sx={{ color: Colors.dark1 }} />
          </IconButton>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <PrimaryButton
          type="submit"
          title={"Submit"}
          loading={loading}
          buttonStyle={{
            color: Colors.white,
            p: "15px",
            minWidth: "100%"
          }}
        />
      </DialogActions>
    </Dialog >
  )
};

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBack = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNext = (event) => {
    onPageChange(event, page + 1);
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <ButtonGroup
        sx={{
          borderRadius: 2,
          background: Colors.dark1,
        }}
      >
        <IconButton
          onClick={handleBack}
          disabled={page == 0}
          aria-label="previous page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNext}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </ButtonGroup>
    </Box>
  )
};

function Notifications() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(tableHead);
  const [anchor, setAnchor] = useState(null);
  const [id, setId] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleToggleColumn = (column) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.name === column ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const handleSort = (column) => {
    setColumns((prevColumns) => {
      const newColumns = prevColumns.map((col) =>
        col.name === column
          ? { ...col, sortOrder: { column, direction: col.sortOrder.direction === 'asc' ? 'desc' : 'asc' } }
          : col
      );

      const sortedRows = [...rows].sort((a, b) => {
        const col = newColumns.find(col => col.name === column);
        const direction = col.sortOrder.direction;
        if (a[column.toLowerCase()] < b[column.toLowerCase()]) return direction === 'asc' ? -1 : 1;
        if (a[column.toLowerCase()] > b[column.toLowerCase()]) return direction === 'asc' ? 1 : -1;
        return 0;
      });

      setRows(sortedRows);
      return newColumns;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllCustomNotifications(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    getAllCustomNotifications(page, event.target.value);
  };

  const handleSettingsClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchor(null);
  };

  const getAllCustomNotifications = async (page, limit, cust_noti_id, title, created_at_from, created_at_to) => {
    try {
      const result = await AdminServices.getCustomNotifications(
        page ? page : 1,
        limit ? limit : 10,
        cust_noti_id ? cust_noti_id : "",
        title ? title : "",
        created_at_from ? created_at_from : "",
        created_at_to ? created_at_to : ""
      );
      if (result.responseCode == 200) {
        setCount(result?.data?.totalNotifications ? result?.data?.totalNotifications : 0)
        const shallowCopy = result?.data?.notifications?.map((item, index) => {
          const obj = {};
          tableHead.forEach((value) => {
            if (value.name == "Action") {
              obj[value.sortOrder.column] = "action";
            } else if (value.name == "ID") {
              obj[value.sortOrder.column] = item.cust_noti_id ? item[value.sortOrder.column] : index + 1;
            } else if (value.name == "Time") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Date") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Title") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Content") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            }
          });
          obj.visible = true;
          return obj;
        })
        setRows(shallowCopy);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = () => {
    setId("");
    setFromDate(null);
    setToDate(null);
    setTitle("");
    getAllCustomNotifications(page, limit);
  };

  useEffect(() => {
    getAllCustomNotifications();
  }, [open])

  return (
    <Fragment>
      <NotificationDialog open={open} handleClose={() => setOpen(false)} />
      <Grid container alignItems={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb pageName={"Notification"} />
        </Grid>
        <Grid item md={10.5}>
          <Box
            sx={{
              p: 2
            }}
          >
            <Filter
              onClick={() => handleReset()}
              items={
                [
                  <Grid item md={2.5}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        width: "100%"
                      }}
                    >
                      <InputField
                        value={id}
                        fullWidth={true}
                        placeholder={"ID"}
                        onChange={(e) => setId(e.target.value)}
                      />
                    </Box>
                  </Grid>,

                  <Grid item md={2.8}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <DateField
                      value={fromDate}
                      placeholder={"From"}
                      onChange={(date) => setFromDate(date)}
                    />
                  </Grid>,

                  <Grid item md={2.8}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <DateField
                      value={toDate}
                      placeholder={"To"}
                      onChange={(date) => setToDate(date)}
                    />
                  </Grid>,

                  <Grid item md={2.5}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        width: "100%"
                      }}
                    >
                      <InputField
                        value={title}
                        placeholder={"Title"}
                        fullWidth={true}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Box>
                  </Grid>,
                ]
              }
            />
          </Box>
        </Grid>
        <Grid item md={1.5}>
          <Box
            sx={{ p: 2 }}
          >
            <PrimaryButton
              title={"Search"}
              onClick={() => getAllCustomNotifications(
                "",
                "",
                id,
                title,
                fromDate,
                toDate
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          background: Colors.dark3,
          p: "0px 16px"
        }}
      >
        <Box
          sx={{
            background: Colors.dark1,
            p: "8px 24px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            borderRadius: "20px",
            height: "calc(100vh - 285px)"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative"
            }}
          >
            <PageDot top={"14px"} left={"-24px"} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2
              }}
            >
              <BusinessIcon2 />
              <Typography sx={{ color: Colors.white }}>
                Notification
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1
              }}
            >
              <PrimaryButton title={"Add New"} onClick={() => setOpen(true)} />
              <IconButton
                onClick={handleSettingsClick}
              >
                <MoreHoriz sx={{ color: Colors.white }} />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={handleSettingsClose}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      background: Colors.dark3,
                      color: Colors.white,
                      overflow: 'visible',
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 15,
                        width: 10,
                        height: 10,
                        bgcolor: Colors.dark3,
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 1,
                      },
                      ".MuiList-root": {
                        p: 0
                      }
                    }
                  }
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box
                  component={"div"}
                  sx={{ maxHeight: ITEM_HEIGHT * 4.5, overflowY: 'auto' }}
                >
                  {columns.map((col) => (
                    <MenuItem key={col.name}>
                      <Checkbox
                        sx={{
                          color: `${Colors.primary} !important`
                        }}
                        checked={col.visible}
                        onChange={() => handleToggleColumn(col.name)}
                      />
                      <ListItemText
                        primary={col.name}
                      />
                    </MenuItem>
                  ))}
                </Box>
              </Menu>
            </Box>
          </Box>
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  background: Colors.dark3
                }}
              >
                <TableRow>
                  {columns.map((cell, ind) => cell.visible && (
                    <TableCell
                      key={ind}
                      sx={{
                        p: "10px 16px",
                        display: "table-cell",
                        borderBottom: "none",
                        borderRadius: ind == 0
                          ? "20px 0 0 20px"
                          : ind == tableHead.length - 1
                            ? "0 20px 20px 0"
                            : "none",
                      }}
                    >
                      {cell.name != "Action" ? (
                        <TableSortLabel
                          active={cell.sortOrder.column == cell.name}
                          direction={cell.sortOrder.direction}
                          onClick={() => handleSort(cell.name)}
                          sx={{
                            "svg": {
                              fontSize: "12px",
                              color: `${Colors.white} !important`,
                            }
                          }}
                        >
                          <Box
                            sx={{
                              color: Colors.white,
                            }}
                          >
                            {cell.name}
                          </Box>
                        </TableSortLabel>
                      ) : (
                        <Box
                          sx={{
                            color: Colors.white,
                          }}
                        >
                          {cell.name}
                        </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, ind) => (
                  <TableRow key={ind}>
                    {columns?.map((col, colInd) => col.visible && (
                      col.name.toLowerCase() == "action" ? (
                        <TableCell key={colInd}
                          sx={{
                            p: "8px 16px"
                          }}
                        >
                          <IconButton
                            sx={{
                              color: Colors.white
                            }}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </TableCell>
                      ) : col.name == "ID" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          {row[col.sortOrder.column]}
                        </TableCell>
                      ) : col.name == "Time" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          {moment(row[col.sortOrder.column]).format("HH:mm A")}
                        </TableCell>
                      ) : col.name == "Date" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          {moment(row[col.sortOrder.column]).format("DD MMM YYYY")}
                        </TableCell>
                      ) : col.name == "Title" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          <Tooltip title={row[col.sortOrder.column]}>
                            {row[col.sortOrder.column].length > 12
                              ? row[col.sortOrder.column].slice(0, 12) + "..."
                              : row[col.sortOrder.column]
                            }
                          </Tooltip>
                        </TableCell>
                      ) : col.name == "Content" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          <Tooltip title={row[col.sortOrder.column]}>
                            {row[col.sortOrder.column].length > 20
                              ? row[col.sortOrder.column].slice(0, 20) + "..."
                              : row[col.sortOrder.column]
                            }
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          {row[col.name.toLowerCase()]}
                        </TableCell>
                      )
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Table>
        <TablePagination
          sx={{
            borderBottom: "none",
            borderRadius: "12px",
            color: Colors.white,
            ".MuiTablePagination-spacer": {
              flex: 1,
            },
            ".MuiToolbar-root": {
              gap: "12px",
              paddingRight: "24px"
            }
          }}
          slotProps={{
            select: {
              MenuProps: {
                slotProps: {
                  paper: {
                    sx: {
                      background: Colors.dark3
                    }
                  },
                },
                MenuListProps: {
                  sx: {
                    color: Colors.white,
                    "li": {
                      ":hover": {
                        background: Colors.buttonGradient
                      }
                    }
                  },
                },
              }
            }
          }}
          rowsPerPageOptions={[20, 50, 100]}
          colSpan={12}
          count={count}
          rowsPerPage={limit}
          page={page}
          labelRowsPerPage={"Showing"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Table>
    </Fragment>
  )
};

export default Notifications;