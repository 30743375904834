import React, { Fragment, useState, useEffect } from 'react';
import BreadCrumb from '../../../components/breadcrumb';
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  TablePagination,
  ButtonGroup,
  TableSortLabel,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  Tooltip,
  Chip,
} from '@mui/material';
import Colors from '../../../style';
import Filter from '../../../components/filter';
import { PrimaryButton, PrimaryIconButton } from '../../../components/buttons';
import { MoreHoriz, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { BusinessIcon2, MemberListIcon } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import AdminServices from '../../../api/admin/AdminServices';
import moment from 'moment';
import { InputField, DateField } from '../../../components/inputfields';
import { PageDot } from '../../../components/UI';
import { Capitalize } from '../../../utils/Capitalize';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import { SaveAlt } from '@mui/icons-material';

const tableHead = [
  {
    name: "ID",
    sortOrder: { column: "business_id", direction: 'asc' },
    visible: true
  },
  {
    name: "Business Name",
    sortOrder: { column: "company_name", direction: 'asc' },
    visible: true
  },
  {
    name: "Contact",
    sortOrder: { column: "phone", direction: 'asc' },
    visible: true
  },
  {
    name: "Membership",
    sortOrder: { column: "membershipStatus", direction: 'asc' },
    visible: true
  },
  {
    name: "Date",
    sortOrder: { column: "created_at", direction: 'asc' },
    visible: true
  },
  {
    name: "Action",
    sortOrder: { column: "action", direction: 'asc' },
    visible: true
  },
];

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBack = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNext = (event) => {
    onPageChange(event, page + 1);
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <ButtonGroup
        sx={{
          borderRadius: 2,
          background: Colors.dark1,
        }}
      >
        <IconButton
          onClick={handleBack}
          disabled={page == 0}
          aria-label="previous page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNext}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </ButtonGroup>
    </Box>
  )
}

const ITEM_HEIGHT = 48;

function BusinessesList() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(tableHead);
  const [anchor, setAnchor] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [search, setSearch] = useState(null);
  const navigate = useNavigate();

  const handleToggleColumn = (column) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.name === column ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const handleSort = (column) => {
    setColumns((prevColumns) => {
      const newColumns = prevColumns.map((col) =>
        col.sortOrder.column === column
          ? { ...col, sortOrder: { column, direction: col.sortOrder.direction === 'asc' ? 'desc' : 'asc' } }
          : col
      );

      const sortedRows = [...rows].sort((a, b) => {
        const col = newColumns.find(col => col.sortOrder.column === column);
        const direction = col.sortOrder.direction;
        if (a[column.toLowerCase()] < b[column.toLowerCase()]) return direction === 'asc' ? -1 : 1;
        if (a[column.toLowerCase()] > b[column.toLowerCase()]) return direction === 'asc' ? 1 : -1;
        return 0;
      });

      setRows(sortedRows);
      return newColumns;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getBusinessData(newPage + 1, limit, "", selectedId, selectedFromDate, selectedToDate)
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    getBusinessData(page, event.target.value);
  };

  const handleSettingsClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchor(null);
  };

  const getBusinessData = async (
    page, limit, is_approved, business_id, created_at_from, created_at_to, name, search
  ) => {
    try {
      const result = await AdminServices.getAllBusiness(
        page ? page : 1,
        limit ? limit : 20,
        is_approved ? is_approved : "accepted",
        business_id ? business_id : "",
        created_at_from ? created_at_from : "",
        created_at_to ? created_at_to : "",
        name ? name : "",
        search ? search : ""
      );
      if (result.responseCode == 200) {
        setCount(result?.data?.totalBusinessProfiles)
        const shallowCopy = result?.data?.BusinessProfile?.map((item) => {
          const obj = { ...item };
          tableHead.forEach((value) => {
            if (value.name == "Action") {
              obj[value.sortOrder.column] = "action";
            } else if (value.name == "ID") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Business Name") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Contact") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Membership") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Date") {
              obj[value.sortOrder.column] = moment(item.created_at).format("DD MMM YYYY");
            } else {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            }
          });
          obj.visible = true;
          return obj;
        })
        setRows(shallowCopy);
      }
    } catch (e) {
      console.log(e)
    }
  };

  const handleNavigate = async (row) => {
    navigate(`/business-detail/${row.user_id}`, {
      state: {
        navigateFrom: "list",
      }
    });
  };

  const handleReset = () => {
    setSearch(null);
    setSelectedId("")
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setBusinessName("");
    getBusinessData(page, limit);
  };

  const handleDownload = () => {
    const head = tableHead.filter((item) => item.name != "Action").map((item) => item.name);
    const data = rows.map(item => [
      item.business_id,
      item.company_name,
      item.phone,
      item.membership,
      item.created_at,
    ]);

    const ws = XLSX.utils.aoa_to_sheet([head, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Convert the workbook to an array buffer
    const buf = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the file using FileSaver.js
    saveAs(new Blob([buf]), "business.xlsx");
  };

  useEffect(() => {
    getBusinessData();
  }, []);

  return (
    <Fragment>
      <Grid container alignItems={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb pageName={"Businesses List"} />
        </Grid>
        <Grid item md={11.3}>
          <Box
            sx={{
              p: 2
            }}
          >
            <Filter
              onClick={handleReset}
              items={
                [
                  <Grid item md={4.2}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        width: "100%"
                      }}
                    >
                      <InputField
                        value={search}
                        fullWidth={true}
                        placeholder={"Search"}
                        type={"text"}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value != "") {
                            setSearch(value);
                          } else {
                            setSearch(null);
                          }
                        }}
                      />
                    </Box>
                  </Grid>,

                  <Grid item md={3.4}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <DateField
                      value={selectedFromDate}
                      placeholder={"Added From"}
                      onChange={(date) => setSelectedFromDate(date)}
                    />
                  </Grid>,

                  <Grid item md={3.4}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <DateField
                      value={selectedToDate}
                      placeholder={"Added To"}
                      onChange={(date) => setSelectedToDate(date)}
                    />
                  </Grid>,
                ]
              }
            />
          </Box>
        </Grid>
        <Grid item md={0.7}>
          <Box
            sx={{ py: 2, pr: 2 }}
          >
            <PrimaryIconButton
              onClick={() => getBusinessData(
                "",
                "",
                "",
                selectedId,
                selectedFromDate,
                selectedToDate,
                businessName,
                search
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          background: Colors.dark3,
          p: "0px 16px"
        }}
      >
        <Box
          sx={{
            background: Colors.dark1,
            p: "16px 24px 8px 24px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            borderRadius: "20px",
            height: "calc(100vh - 293px)"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative"
            }}
          >
            <PageDot top={"14px"} left={"-24px"} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2
              }}
            >
              <BusinessIcon2 />
              <Typography sx={{ color: Colors.white }}>
                Businesses
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1
              }}
            >
              <IconButton
                sx={{
                  background: Colors.buttonGradient
                }}
                onClick={() => handleDownload()}
              >
                <SaveAlt sx={{ color: Colors.white }} />
              </IconButton>
              <IconButton
                onClick={handleSettingsClick}
              >
                <MoreHoriz sx={{ color: Colors.white }} />
              </IconButton>
            </Box>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={handleSettingsClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    background: Colors.dark3,
                    color: Colors.white,
                    overflow: 'visible',
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 15,
                      width: 10,
                      height: 10,
                      bgcolor: Colors.dark3,
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 1,
                    },
                    ".MuiList-root": {
                      p: 0
                    }
                  }
                }
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box
                component={"div"}
                sx={{ maxHeight: ITEM_HEIGHT * 4.5, overflowY: 'auto' }}
              >
                {columns.map((col) => (
                  <MenuItem key={col.name}>
                    <Checkbox
                      sx={{
                        color: `${Colors.primary} !important`
                      }}
                      checked={col.visible}
                      onChange={() => handleToggleColumn(col.name)}
                    />
                    <ListItemText
                      primary={col.name}
                    />
                  </MenuItem>
                ))}
              </Box>
            </Menu>
          </Box>
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  background: Colors.dark3
                }}
              >
                <TableRow>
                  {columns.map((cell, ind) => cell.visible && (
                    <TableCell
                      key={ind}
                      sx={{
                        p: "10px 16px",
                        display: "table-cell",
                        borderBottom: "none",
                        borderRadius: ind == 0
                          ? "20px 0 0 20px"
                          : ind == tableHead.length - 1
                            ? "0 20px 20px 0"
                            : "none",
                      }}
                    >
                      {cell.name != "Action" ? (
                        <TableSortLabel
                          active={cell.sortOrder.column == cell.name}
                          direction={cell.sortOrder.direction}
                          onClick={() => handleSort(cell.sortOrder.column)}
                          sx={{
                            "svg": {
                              fontSize: "12px",
                              color: `${Colors.white} !important`,
                            }
                          }}
                        >
                          <Box
                            sx={{
                              color: Colors.white,
                            }}
                          >
                            {cell.name}
                          </Box>
                        </TableSortLabel>
                      ) : (
                        <Box
                          sx={{
                            color: Colors.white,
                          }}
                        >
                          {cell.name}
                        </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, ind) => (
                  <TableRow key={ind}>
                    {columns.map((col, colInd) => col.visible && (
                      row[col.sortOrder.column] == "action" ? (
                        <TableCell key={colInd}
                          sx={{
                            p: "8px 16px"
                          }}
                        >
                          <IconButton
                            sx={{
                              color: Colors.white
                            }}
                            onClick={() => handleNavigate(row)}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </TableCell>
                      ) : col.name == "Membership" ? (
                        <TableCell key={colInd}
                          sx={{
                            p: "8px 16px"
                          }}
                        >
                          <Chip
                            label={Capitalize(row?.membershipStatus)}
                            sx={{
                              background: row?.membershipStatus == "active"
                                ? Colors.success + 19
                                : row?.membershipStatus == "pending" || row?.membershipStatus == "submitted"
                                  ? Colors.warning + 19
                                  : Colors.danger + 19,
                              color: row?.membershipStatus == "active"
                                ? Colors.success
                                : row?.membershipStatus == "pending" || row?.membershipStatus == "submitted"
                                  ? Colors.warning
                                  : Colors.danger,
                              width: "100px"
                            }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          {row[col.sortOrder.column]}
                        </TableCell>
                      )
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Table>
        <TablePagination
          sx={{
            borderBottom: "none",
            borderRadius: "12px",
            color: Colors.white,
            ".MuiTablePagination-spacer": {
              flex: 1,
            },
            ".MuiToolbar-root": {
              gap: "12px",
              paddingRight: "24px",
              ".MuiInputBase-root": {
                mr: 1,
              }
            },
          }}
          slotProps={{
            select: {
              MenuProps: {
                slotProps: {
                  paper: {
                    sx: {
                      background: Colors.dark3
                    }
                  },
                },
                MenuListProps: {
                  sx: {
                    color: Colors.white,
                    "li": {
                      ":hover": {
                        background: Colors.buttonGradient
                      }
                    }
                  },
                },
              }
            }
          }}
          rowsPerPageOptions={[20, 50, 100]}
          colSpan={12}
          count={count}
          rowsPerPage={limit}
          page={page}
          labelRowsPerPage={"Showing"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Table>
    </Fragment>
  )
}

export default BusinessesList;